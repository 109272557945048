import useSWR from "swr";
import { defaultFetcher, internalFetcher } from "../HTTPClient";
import { JSONAPIDocument, serializers } from "../Serializer";
import { APIInternal } from "../HTTPClientInternal";
import { APIV3 } from "../HTTPClientV3";
import {
  filterBuilder,
  includesBuilder,
  sortingBuilder,
} from "../QueryBuilder";
import { REVIEWS_ALLOWED_FEATURE_KEY, useConfiguration } from "./configuration";

export interface ReviewPayload {
  quote: string;
  ratingStars: number;
}

export interface Review {
  reviewerName: string;
  ratingStars: 1 | 2 | 3 | 4 | 5;
  quote: string;
  isEdited: boolean;
  reviewerPhoto: string;
  reviewerOrganizationName: string;
}
export interface PostCaptchaPayload extends ReviewPayload {
  token: string;
  slug: string;
}

export const RATING_STARS_FILTER_KEY = "rating_stars";
export const REVIEWER_PICTURE_INCLUDES_KEY = "reviewer_picture";
const baseCaptcha = "/api/review";
const baseItems = "/items";

export function postVerifyCaptcha(payload: PostCaptchaPayload) {
  const url = `${baseCaptcha}/verifycaptcha`;
  const request = () =>
    APIInternal.post<JSONAPIDocument<Review>>(url, {
      body: JSON.stringify(payload),
    });
  return {
    json: () => internalFetcher(request),
    fallbackKey: url,
  };
}

export function createReviewBySlug(slug: string, payload: ReviewPayload) {
  const url = `${baseItems}/${slug}/reviews`;
  const request = () =>
    APIV3.post<JSONAPIDocument<Review>>(url, {
      body: JSON.stringify(serializers.reviews.serialize(payload)),
    });

  return {
    json: async () => defaultFetcher(request, serializers.reviews),
    fallbackKey: url,
  };
}

export function getReviewsBySlug(
  slug?: string,
  filter?: string,
  sorting?: string,
) {
  let url = `${baseItems}/${slug}/reviews?${includesBuilder({
    values: [REVIEWER_PICTURE_INCLUDES_KEY],
  })}`;
  if (filter) {
    url = url.concat(filterBuilder(RATING_STARS_FILTER_KEY, { value: filter }));
  }
  if (sorting) {
    url = url.concat(
      sortingBuilder(RATING_STARS_FILTER_KEY, { asc: sorting !== "highest" }),
    );
  }
  const request = () => APIV3.get<JSONAPIDocument<Review[]>>(url);

  return {
    json: async () => defaultFetcher(request, serializers.reviews),
    fallbackKey: url,
  };
}

export function useReviews(slug?: string, filter?: string, sorting?: string) {
  const { configuration } = useConfiguration();
  const { fallbackKey, json } = getReviewsBySlug(slug, filter, sorting);

  const { data, isValidating, error } = useSWR(
    slug && configuration?.allowedFeatures.includes(REVIEWS_ALLOWED_FEATURE_KEY)
      ? fallbackKey
      : null,
    json,
  );

  return {
    loadingReviews: isValidating,
    reviews: data?.data,
    error,
  };
}
