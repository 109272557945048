import { CSSInterpolation, styled, useTheme } from "@mui/material";
import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { renderToStaticMarkup } from "react-dom/server";
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, Grid, Thumbs } from "swiper";
import type { Property } from "csstype";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/thumbs";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import { Size, ThemeSettings } from "../../types/theme";
import { ThemeButton } from "./ThemeComponents/ThemeButton";

type NavigationPosition = "around" | "center";

interface Props {
  children: ReactNode[];
  disableOnDesktop?: boolean;
  disableOnMobile?: boolean;
  slidesPerViewOnDesktop?: SwiperProps["slidesPerView"];
  rowsPerView?: number;
  verticalHeight?: Property.Height;
  vertical?: boolean;
  fullWidthSwiper?: boolean;
  overrideSettings?: ThemeSettings["carousels"];
  thumbs?: SwiperProps["thumbs"];
  onSwiper?: SwiperProps["onSwiper"];
  itemMaxWidthPercentage?: number;
  paginationOptions?: {
    disabled?: boolean;
  };
  navigationOptions?: {
    position?: NavigationPosition;
    disabled?: boolean;
  };
}

interface VerticalSupport {
  vertical: boolean;
}

const CenterArrowsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "none",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

interface DisabledOptions {
  disableOnDesktop?: boolean;
  disableOnMobile?: boolean;
}

const CustomPagination = styled("div")<
  {
    settings: ThemeSettings["carousels"];
  } & DisabledOptions
>(({ theme, settings, disableOnDesktop, disableOnMobile }) => ({
  display: disableOnMobile ? "none" : "flex",
  marginTop: "1rem",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display:
      settings.variant === "arrows" || disableOnDesktop ? "none" : "flex",
  },
}));

const SliderContainer = styled("div")<
  VerticalSupport & Pick<Props, "navigationOptions">
>(({ vertical, navigationOptions }) => ({
  maxHeight: "100%",
  width: "100%",
  display: "flex",
  position: "relative",
  flexDirection:
    navigationOptions?.position === "center" || vertical ? "column" : "initial",
}));

export const NavigationArrow = styled(ThemeButton)<
  VerticalSupport & {
    position: "left" | "right" | "center";
  } & DisabledOptions
>(({
  theme,
  disabled,
  vertical,
  position,
  disableOnDesktop,
  disableOnMobile,
}) => {
  const verticalDisabled: CSSProperties = {
    visibility: "hidden",
  };
  const horizontalDisabled: CSSProperties = {
    height: 0,
    overflow: "hidden",
  };
  const disabledOptions = vertical ? verticalDisabled : horizontalDisabled;
  const sizes: Record<Size["size"], number> = {
    extraSmall: 2.1,
    small: 2.2,
    medium: 2.3,
    large: 2.6,
    extraLarge: 3,
  };
  const size = `${sizes[theme.settings.buttons.size] || sizes.medium}rem`;
  return {
    color: theme.palette.secondary.main,
    minWidth: 0,
    padding: 0,
    width: size,
    height: size,
    ...(position !== "center"
      ? {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          [position]: "-1.2rem",
        }
      : {}),

    zIndex: theme.zIndex.appBar,
    ...(disabled ? disabledOptions : {}),
    "& svg": {
      transform: vertical ? "rotate(90deg)" : "none",
      border: "none",
      fill: theme.palette.buttons.contrastText,
    } as CSSInterpolation,
    display: disableOnMobile ? "none" : "flex",
    marginTop: ".5rem",
    "&:hover": {
      opacity: 0.4,
    },
    [theme.breakpoints.up("md")]: {
      display: disableOnDesktop ? "none" : "flex",
      alignItems: "center",
      alignSelf: "center",
      "&:hover": {
        opacity: 1,
      },
    },
  };
});

const Slide = styled(SwiperSlide)<{
  disableOnDesktop?: boolean;
  vertical?: boolean;
  fullWidthSwiper?: boolean;
  itemMaxWidthPercentage?: number;
}>(
  ({
    disableOnDesktop,
    vertical,
    fullWidthSwiper,
    itemMaxWidthPercentage,
    theme,
  }) => ({
    "& > *": {
      ...(vertical ? { maxHeight: "100%" } : {}),
    },
    width: fullWidthSwiper ? "100%" : "auto !important",
    height: "auto !important",
    flex: disableOnDesktop ? 1 : "none",
    [theme.breakpoints.up("md")]: {
      maxWidth: itemMaxWidthPercentage ? `${itemMaxWidthPercentage}%` : "100%",
    },
  }),
);

const swiperPadding = "2rem";
const StyledSwiper = styled(Swiper)<
  Pick<
    Props,
    | "disableOnDesktop"
    | "disableOnMobile"
    | "rowsPerView"
    | "verticalHeight"
    | "paginationOptions"
    | "itemMaxWidthPercentage"
  > & {
    settings: ThemeSettings["carousels"];
  }
>(({
  theme,
  disableOnDesktop,
  disableOnMobile,
  rowsPerView,
  verticalHeight,
  paginationOptions,
  settings,
}) => {
  const shouldWrap = rowsPerView && rowsPerView > 1;
  return {
    margin: 0,
    paddingBottom: paginationOptions?.disabled ? "0" : "2rem",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "0",
    },
    "&.swiper": {
      margin: `-${swiperPadding}`,
      padding: swiperPadding,
      width: `calc(100% + ${swiperPadding} * 30)`,
      height: verticalHeight,
      "&-vertical": {
        maxHeight: verticalHeight,
      },
    },
    "& .swiper": {
      pointerEvents: "none",
      "&-slide-thumb-active": {
        opacity: 0.5,
      },
      "&-wrapper": {
        paddingBottom: 0,
        flexWrap: disableOnMobile || shouldWrap ? "wrap" : "nowrap",
        [theme.breakpoints.up("md")]: {
          flexWrap: disableOnDesktop || shouldWrap ? "wrap" : "nowrap",
        },
      },
      "&-pagination": {
        bottom: "0 !important",
        [theme.breakpoints.up("md")]: {
          display: settings.variant === "arrows" ? "none" : "block",
        },
      },
    },
  };
});
const Carousel: FunctionComponent<Props> = ({
  children,
  disableOnDesktop,
  disableOnMobile,
  slidesPerViewOnDesktop,
  rowsPerView,
  vertical,
  verticalHeight,
  fullWidthSwiper,
  itemMaxWidthPercentage,
  navigationOptions,
  paginationOptions,
  overrideSettings,
  onSwiper,
  thumbs,
}: Props) => {
  const theme = useTheme();
  const settings = { ...theme.settings.carousels, ...overrideSettings };
  const [swiper, setSwiper] = useState<ReturnType<typeof useSwiper> | null>(
    null,
  );
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { canvasValues, isEditor } = useContext(EditorCtx);
  const wysiwygBreakpoint =
    canvasValues && canvasValues.width >= theme.breakpoints.values.md
      ? 0
      : Infinity;
  const isVertical = !!vertical;
  const sideArrows = settings.variant !== "arrows";

  navigationOptions = {
    disabled: false,
    position: sideArrows ? "around" : "center",
    ...navigationOptions,
  };

  const NavigateBeforeIcon = theme.icons.navigateBefore;
  const NavigateNextIcon = theme.icons.navigateNext;

  const navigationPosition = navigationOptions?.disabled
    ? null
    : navigationOptions?.position;

  const arrowStart = useMemo(
    () => (
      <NavigationArrow
        onClick={() => {
          swiper?.slidePrev();
        }}
        disabled={isBeginning || swiper?.isLocked}
        vertical={isVertical}
        position={sideArrows ? "left" : "center"}
        disableOnDesktop={!!disableOnDesktop}
        disableOnMobile={!!disableOnMobile}
        aria-label="Previous" // TODO: handle translation
      >
        <NavigateBeforeIcon />
      </NavigationArrow>
    ),
    [swiper, isBeginning, isVertical],
  );

  const arrowEnd = useMemo(
    () => (
      <NavigationArrow
        onClick={() => {
          swiper?.slideNext();
        }}
        disabled={isEnd || swiper?.isLocked}
        vertical={isVertical}
        position={sideArrows ? "right" : "center"}
        disableOnDesktop={!!disableOnDesktop}
        disableOnMobile={!!disableOnMobile}
        aria-label="Next" // TODO: handle translation
      >
        <NavigateNextIcon />
      </NavigationArrow>
    ),
    [swiper, isEnd, isVertical],
  );

  const randomId = `carousels-${String(Math.floor(Math.random() * 10000000))}`;

  return (
    <>
      <SliderContainer
        vertical={isVertical}
        navigationOptions={navigationOptions}
      >
        {navigationPosition === "around" &&
          settings.variant !== "bullets" &&
          arrowStart}
        <StyledSwiper
          itemMaxWidthPercentage={itemMaxWidthPercentage}
          simulateTouch
          settings={settings}
          spaceBetween={16}
          pagination={
            !paginationOptions?.disabled && {
              clickable: true,
              el: `#${randomId}`,
              renderBullet: (index, className) => {
                const size = ".6rem";
                return renderToStaticMarkup(
                  <CircleIcon
                    style={{
                      background: theme.palette.secondary.main,
                      border: "none",
                      width: size,
                      height: size,
                    }}
                    className={className}
                  />,
                );
              },
            }
          }
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
            if (onSwiper) {
              onSwiper(swiper);
            }
          }}
          thumbs={thumbs}
          direction={isVertical ? "vertical" : "horizontal"}
          verticalHeight={verticalHeight}
          disableOnDesktop={disableOnDesktop}
          paginationOptions={paginationOptions}
          disableOnMobile={disableOnMobile}
          rowsPerView={rowsPerView}
          grid={{ rows: rowsPerView || 1, fill: "row" }}
          modules={[Pagination, Grid, Thumbs]}
          slidesPerView={rowsPerView ? 1 : "auto"}
          breakpoints={{
            [isEditor ? wysiwygBreakpoint : theme.breakpoints.values.md]: {
              enabled: !disableOnDesktop,
              slidesPerView: slidesPerViewOnDesktop || "auto",
            },
          }}
        >
          {children.map((slide, index) => (
            <Slide
              itemMaxWidthPercentage={itemMaxWidthPercentage}
              fullWidthSwiper={fullWidthSwiper}
              disableOnDesktop={disableOnDesktop}
              vertical={isVertical}
              key={index}
            >
              {slide}
            </Slide>
          ))}
        </StyledSwiper>
        {settings.variant !== "bullets" && (
          <>
            {navigationPosition === "around" && arrowEnd}
            {navigationPosition === "center" && (
              <CenterArrowsContainer>
                {arrowStart}
                {arrowEnd}
              </CenterArrowsContainer>
            )}
          </>
        )}
      </SliderContainer>
      <CustomPagination
        id={randomId}
        settings={settings}
        disableOnDesktop={!!disableOnDesktop}
        disableOnMobile={!!disableOnMobile}
      />
    </>
  );
};

export default Carousel;
