import { ComponentProps, FunctionComponent, useContext } from "react";
import Icon from "@mui/material/Icon";
import { styled } from "@mui/material";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { scrollIntoView } from "../../helpers/navigation";
import { iframe } from "../../helpers/environment";

import { ProfileContext, profileDataDesktopAttribute } from "../public/Profile";
import { ThemeButton } from "../ui/ThemeComponents/ThemeButton";

interface Props {
  children: string;
  className?: string;
}

const StyledButton = styled(ThemeButton)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "unset",
  },
}));

const LoginAnchorButton: FunctionComponent<Props> = ({ children }: Props) => {
  const { setLoginOpen } = useContext(ProfileContext);
  const { configuration } = useConfiguration();
  const loginToClaimOnClick = (profileDataAttribute: string) => {
    if (configuration?.ssoSetting.forceJwtAuth) {
      return;
    }
    const element = document.querySelector(`[${profileDataAttribute}]`);

    if (!element) {
      if (setLoginOpen) {
        setLoginOpen(() => true);
      }
      return;
    }

    scrollIntoView({
      element,
      onEnd: () => {
        if (setLoginOpen) {
          setLoginOpen(() => true);
        }
      },
    });
  };

  const loginButtonTarget =
    iframe && configuration?.ssoSetting.forceJwtAuth
      ? { target: "_parent" }
      : {};

  const loginButtonHref = configuration?.ssoSetting.forceJwtAuth
    ? { href: configuration.ssoSetting.signInRedirectUrl }
    : {};

  const loginButtonAttributes: ComponentProps<typeof ThemeButton> = {
    startIcon: <Icon>lock</Icon>,
    variant: "contained",
    onClick: () => loginToClaimOnClick(profileDataDesktopAttribute),
    ...loginButtonHref,
    ...loginButtonTarget,
  };

  return <StyledButton {...loginButtonAttributes}>{children}</StyledButton>;
};

export default LoginAnchorButton;
