import { FunctionComponent, ReactNode, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { useUser } from "../../dataAccess/api/user";
import { Alignment } from "../../types/theme";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import TextField from "./TextField";
import { GuestFlowRedemption } from "../../dataAccess/api/deal";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { checkIfEmailChanged, DealSettings } from "../public/Deal";
import { Item } from "../../dataAccess/api/item";
import { isGuestUser } from "../../dataAccess/api/guestUser";
import { ButtonProgress } from "./ButtonProgress";
import TermsText from "./TermsText";

interface Props {
  onSubmit: (data: GuestFlowRedemption) => void;
  loading?: boolean;
  guestUserCreated: boolean;
  alignment: Alignment;
  item?: Item;
  statusSection: ReactNode;
  redemptionButtons: DealSettings["redemptionButtons"];
}

const schema = yup.object().shape({
  email: yup
    .string()
    .transform((value) => value.trim())
    .email()
    .required("Email is a required field."),
  signUpOrganizationName: yup
    .string()
    .required("Organization Name is a required field."),
  fullName: yup.string().required("Full Name is a required field."),
});

const StyledForm = styled("form")<Pick<Props, "alignment">>(
  ({ alignment }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: getJustificationByAlignment(alignment),
  }),
);

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  [theme.breakpoints.up("lg")]: {
    flexWrap: "nowrap",
    justifyContent: "initial",
  },
}));

const TermsAndConditions = styled(FormControlLabel)(() => ({
  margin: 0,
  marginTop: "1rem",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    "& .MuiFilledInput-root": {
      marginBottom: "0",
    },
    width: "auto",
  },
}));

export const StyledButton = styled(ThemeButton)<{ errors: boolean }>(
  ({ errors, theme }) => ({
    marginRight: "0",
    marginBottom: "0.5rem",
    minWidth: "8rem",
    width: "100%",
    marginTop: errors ? "0.4rem" : 0,
    alignSelf: errors ? "baseline" : "center",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginRight: "1rem",
      marginBottom: "0",
    },
  }),
);

const DealForm: FunctionComponent<Props> = ({
  alignment,
  loading,
  guestUserCreated,
  item,
  onSubmit,
  statusSection,
  redemptionButtons,
}: Props) => {
  const { user } = useUser();
  const { configuration } = useConfiguration();
  const { settings } = useTheme();
  const showTermsAndConditions =
    !configuration?.customDomain || settings?.termsAndConditions;
  let validationSchema = schema;
  if (showTermsAndConditions) {
    validationSchema = validationSchema.shape({
      termsAndConditions: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept terms and conditions before continuing.",
        ),
    });
  }
  const userEmail = user?.email;
  const userOrgName = user?.organization?.name || user?.signUpOrganizationName;
  const userFirstName = user?.firstName;
  const userLastName = user?.lastName;
  const guestUser = isGuestUser(user);
  const { control, formState, handleSubmit } = useForm<GuestFlowRedemption>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: userEmail,
      signUpOrganizationName: userOrgName,
      fullName:
        userFirstName || userLastName
          ? `${userFirstName || ""} ${userLastName || ""}`.trim()
          : "",
      termsAndConditions: false,
    },
  });

  const [hadEmailChanged, setHadEmailChanged] = useState(false);

  const redemption = item?.deal?.redemption[0];
  function updateHadEmailChanged(e: React.ChangeEvent<HTMLInputElement>) {
    return setHadEmailChanged(checkIfEmailChanged(e.target.value, userEmail));
  }

  const { claimNow, getApproval, resendApproval } =
    redemptionButtons?.emailRedemptions || {};
  const claimText = claimNow;
  const approvalText =
    guestUserCreated && !hadEmailChanged ? resendApproval : getApproval;

  return (
    <>
      <StyledForm
        alignment={alignment}
        onSubmit={handleSubmit(onSubmit)}
        aria-label="Registration Form" // TODO: handle translation
      >
        <StyledInputsContainer>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                label="Email"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateHadEmailChanged(e)
                }
                error={!!formState.errors.email}
                helperText={
                  formState.errors.email ? formState.errors.email.message : ""
                }
              />
            )}
          />
          <Controller
            name="signUpOrganizationName"
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                label="Organization Name"
                error={!!formState.errors.signUpOrganizationName}
                helperText={
                  formState.errors.signUpOrganizationName
                    ? formState.errors.signUpOrganizationName.message
                    : ""
                }
              />
            )}
          />
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                label="Full Name"
                error={!!formState.errors.fullName}
                helperText={
                  formState.errors.fullName
                    ? formState.errors.fullName.message
                    : ""
                }
              />
            )}
          />
          {(!redemption?.step || hadEmailChanged || guestUser) && (
            <StyledButton
              startIcon={loading ? <ButtonProgress /> : null}
              variant="contained"
              type="submit"
              errors={!!Object.keys(formState.errors).length}
              disabled={loading}
            >
              {user?.id && !guestUser && !hadEmailChanged
                ? claimText
                : approvalText}
            </StyledButton>
          )}
        </StyledInputsContainer>
        {showTermsAndConditions && (
          <Controller
            name="termsAndConditions"
            control={control}
            render={({ field }) => (
              <>
                <TermsAndConditions
                  control={
                    <Checkbox
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label={
                    <Typography>
                      <TermsText />
                    </Typography>
                  }
                />
                {formState.errors.termsAndConditions && (
                  <FormHelperText error>
                    {formState.errors.termsAndConditions.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        )}
      </StyledForm>
      {!hadEmailChanged && statusSection}
    </>
  );
};

export default DealForm;
