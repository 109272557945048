import { FunctionComponent } from "react";
import MoreInformationSection from "../ui/MoreInformationSection";
import { ChildrenProps } from "./MoreInformation";
import type { MoreInformationListSettings } from "./CategoryList";

export function displayCollectionList(item: Props["item"]) {
  return !!item?.collections?.length;
}

type Props = ChildrenProps<MoreInformationListSettings>;

const CollectionList: FunctionComponent<Props> = ({
  parentComponent,
  item,
  settings,
}) =>
  item && displayCollectionList(item) ? (
    <MoreInformationSection
      variant="chip"
      items={item.collections.map(({ name }) => ({ label: `#${name}` }))}
      title={settings?.title || "Collections"}
      config={parentComponent?.settings}
    />
  ) : (
    <></>
  );

export default CollectionList;
