import React, { Fragment, FunctionComponent } from "react";
import { styled } from "@mui/material";
import { LayoutComponent } from "../../../builder/Layout";
import { Position } from "../../../types/theme";
import type { Settings as MoreInformationSettings } from "../MoreInformation";
import type { Settings as HowItWorkSettings } from "../HowItWorks";
import type { Settings as FeatureSettings } from "../Features";
import { Container } from "../../ui/DefaultPageContainer";
import { PublicComponentProps } from "../PublicComponentProps";

export interface Subcomponents {
  Quote: LayoutComponent<unknown>;
  Deal: LayoutComponent<unknown>;
  Features?: LayoutComponent<FeatureSettings>;
  Gallery?: LayoutComponent<unknown>;
  HowItWorks?: LayoutComponent<HowItWorkSettings>;
  Reviews?: LayoutComponent<unknown>;
  Pricing?: LayoutComponent<unknown>;
  MoreInformation?: LayoutComponent<MoreInformationSettings>;
  CustomersWhoUse?: LayoutComponent<unknown>;
  SimilarTo?: LayoutComponent<unknown>;
}

export const dealLayoutSideContentWidth = "23rem";

const Main = styled("div")<{
  placement?: Position;
}>(({ placement, theme }) => ({
  marginTop: "2rem",
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "grid",
    gridGap: theme.defaultSpacing.md.page.content,
    paddingLeft: theme.defaultSpacing.md.page.edges,
    paddingRight: theme.defaultSpacing.md.page.edges,
    gridTemplateColumns:
      placement === "vertical"
        ? `${dealLayoutSideContentWidth} minmax(0, 1fr)`
        : "minmax(0, 1fr)",
  },
}));

const DealDetailsLayout: FunctionComponent<
  PublicComponentProps<Record<string, unknown>, Subcomponents>
> = ({ subComponents }) => (
  <Container>
    <Main placement={subComponents?.MoreInformation?.settings.placement}>
      {subComponents &&
        Object.values(subComponents).map((value, idx) => (
          <Fragment key={idx}>{value.component}</Fragment>
        ))}
    </Main>
  </Container>
);

export default DealDetailsLayout;
