import {
  Box,
  Fade,
  Icon,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Image from "next/legacy/image";
import { buildUrlWithQueryParams } from "src/dataAccess/QueryParams";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import { SSR, iframe } from "../../helpers/environment";
import { Alignment } from "../../types/theme";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { Settings as TileBannerSettings } from "../public/TileBanner";
import { Item } from "../../dataAccess/api/item";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import RatingStars from "./RatingStars";
import { SeeMoreButton } from "./SeeMoreButton";

interface Props {
  layoutAlignment?: Alignment;
  item: Item;
  showShareUrlButton: boolean;
  showIntegrationButton: boolean;
  showPartnerDescription: boolean;
  showRedirectPartnerWebsite: boolean;
  showRecommendationStars: boolean;
  textShadow?: boolean;
  postReferral: () => void;
  installNowButton?: TileBannerSettings["installNowButton"];
}

const ShadowTypography = styled(Typography)<{ shadow?: boolean }>(
  ({ shadow }) => ({
    textShadow: shadow ? "1px 1px 3px #fff" : "unset",
  }),
);

const descriptionLineHeight = "1.7rem";
const ProductDescription = styled(ShadowTypography)<{
  flexOrder?: number;
  layoutAlignment?: Alignment;
  showSeeMore: boolean;
}>(({ theme, flexOrder, layoutAlignment, showSeeMore }) => {
  let rulesBasedOnAlignment: CSSProperties = {};
  switch (layoutAlignment) {
    case "center":
      rulesBasedOnAlignment = {
        textAlign: "center",
      };
      break;
    case "right":
      rulesBasedOnAlignment = {
        textAlign: "right",
      };
      break;
    default:
      rulesBasedOnAlignment = { textAlign: "left" };
  }
  return {
    lineHeight: descriptionLineHeight,
    maxHeight: `calc(${descriptionLineHeight} * 3)`,
    overflow: "hidden",
    overflowY: showSeeMore ? "visible" : "hidden",
    width: "100%",
    opacity: 0.7,
    margin: "1rem 0",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.body1.fontSize,
      ...rulesBasedOnAlignment,
      order: flexOrder,
      margin: "0.4rem 0",
    },
  };
});

const CopyToClipBoardButton = styled(CopyToClipboard)<{
  flexOrder?: number;
  showPartnerDescription: TileBannerSettings["showPartnerDescription"];
}>(({ flexOrder, theme, showPartnerDescription }) => {
  const shouldChangeInMobile = flexOrder && flexOrder < 0;
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: !showPartnerDescription ? "1rem" : "unset",
    [theme.breakpoints.up("md")]: {
      order: flexOrder,
      minWidth: shouldChangeInMobile && "unset",
      width: shouldChangeInMobile ? "1.25rem" : "unset",
      boxShadow: shouldChangeInMobile && "none",
      padding: shouldChangeInMobile && 0,
      margin: shouldChangeInMobile && 0,
    },
  };
});

const ActionButton = styled(ThemeButton)<{ flexOrder?: number }>(({
  flexOrder,
  theme,
}) => {
  const shouldChangeInMobile = flexOrder && flexOrder < 0;
  return {
    width: "100%",
    marginBottom: "0.7rem",
    display: "flex",
    gap: "0.7rem",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: "0.5rem",
      order: flexOrder,
      minWidth: shouldChangeInMobile && "unset",
      width: shouldChangeInMobile ? "1.25rem" : "unset",
      backgroundColor: shouldChangeInMobile && "transparent",
      color: shouldChangeInMobile && theme.palette.primary.main,
      boxShadow: shouldChangeInMobile && "none",
      padding: shouldChangeInMobile && 0,
      margin: shouldChangeInMobile && 0,
      "&:hover": {
        backgroundColor: shouldChangeInMobile && "unset",
        boxShadow: shouldChangeInMobile && "none",
      },
    },
  };
});

const ActionButtonIcon = styled(ActionButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    background: "transparent",
    marginRight: `${theme.spacing(0.5)} !important`,
    marginLeft: `${theme.spacing(0.5)} !important`,
    border: "none",
  },
}));

const ActionButtonText = styled("span")<{ keepInMobile?: boolean }>(
  ({ theme, keepInMobile }) => ({
    [theme.breakpoints.up("md")]: {
      display: !keepInMobile ? "none" : "unset",
    },
  }),
);

const ButtonWrapper = styled("div")<{
  flexOrder?: number;
}>(({ flexOrder, theme }) => {
  const shouldChangeInMobile = flexOrder && flexOrder < 0;
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: "0.5rem",
      order: flexOrder,
      minWidth: shouldChangeInMobile && "unset",
      width: shouldChangeInMobile ? "1.25rem" : "unset",
      boxShadow: shouldChangeInMobile && "none",
      padding: shouldChangeInMobile && 0,
      margin: shouldChangeInMobile && 0,
    },
  };
});

const ProductName = styled(ShadowTypography)<{
  layoutAlignment?: Alignment;
  flexOrder?: CSSProperties["order"];
}>(({ theme, flexOrder, layoutAlignment }) => {
  const margin = "0.5rem";
  return {
    fontSize: theme.typography.h2.fontSize,
    marginRight: margin,
    wordBreak: "break-word",
    [theme.breakpoints.up("md")]: {
      marginRight: layoutAlignment === "right" ? 0 : margin,
      marginLeft: layoutAlignment === "right" ? margin : 0,
      order: flexOrder,
    },
  };
});

const ShareIconWrapper = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.h5.fontSize,
    width: "unset",
  },
}));

const OpenInNewIconWrapper = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

const RatingContainer = styled(Box)<{ layoutAlignment?: Alignment }>(({
  theme,
  layoutAlignment,
}) => {
  let rulesBasedOnAlignment: CSSProperties = {};
  switch (layoutAlignment) {
    case "center":
      rulesBasedOnAlignment = { textAlign: "center" };
      break;
    case "right":
      rulesBasedOnAlignment = { textAlign: "right" };
      break;
    default:
      rulesBasedOnAlignment = { textAlign: "left" };
  }
  return {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      ...rulesBasedOnAlignment,
    },
  };
});

const EndorsmentContainer = styled(Box)<{ layoutAlignment?: Alignment }>(
  ({ layoutAlignment, theme }) => ({
    display: "flex",
    width: "100%",
    marginTop: "0.25rem",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      justifyContent: getJustificationByAlignment(layoutAlignment),
    },
  }),
);

const TileBannerBody = ({
  item,
  layoutAlignment,
  showShareUrlButton = true,
  showIntegrationButton = true,
  showPartnerDescription = true,
  showRedirectPartnerWebsite = true,
  showRecommendationStars = true,
  textShadow = false,
  postReferral,
  installNowButton,
}: Props) => {
  const { configuration } = useConfiguration();
  const theme = useTheme();
  const charWidth = 8;
  const [descriptionWidthDiv, setdescriptionWidthDiv] = useState(0);
  const descriptionFieldRef = useRef<HTMLElement>(null);
  const numCharsPerLine = Math.floor(descriptionWidthDiv / charWidth);
  const maxNumChars = 3 * numCharsPerLine - 70;
  const [showSeeMore, setShowSeeMore] = useState(false);
  const {
    seller: { website = "", description = "" },
    productTotalVotes,
    productRateAverage,
    name: itemName,
    slug,
  } = item;
  const toggleSeeMore = () => setShowSeeMore(!showSeeMore);

  const descriptionNeedsSeeMore = (description?.length || 0) > maxNumChars;
  const [openCopiedMessage, setOpenCopiedMessage] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (openCopiedMessage) {
      timer = setTimeout(() => {
        setOpenCopiedMessage(false);
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [openCopiedMessage]);

  useLayoutEffect(() => {
    setdescriptionWidthDiv(descriptionFieldRef.current?.offsetWidth || 0);
  }, []);

  const linkToShare = useMemo(() => {
    if (!SSR) {
      return iframe && configuration?.redirectUrl
        ? buildUrlWithQueryParams(configuration.redirectUrl, {
            bf_redirect: encodeURIComponent(`/${slug}`),
          })
        : window.location.href;
    }
    return "";
  }, [configuration?.redirectUrl, slug]);

  return (
    <>
      <ProductName
        variant="h1"
        data-test="tile-banner-title"
        flexOrder={-5}
        layoutAlignment={layoutAlignment}
        shadow={textShadow}
      >
        {itemName}
      </ProductName>
      {item.endorsed && configuration && (
        <EndorsmentContainer layoutAlignment={layoutAlignment}>
          <Typography color={theme.palette.text.bannerDefault}>
            ENDORSED BY
          </Typography>
          {configuration?.signUpLogoUrl ? (
            <Image
              alt={`${configuration?.name} logo`}
              src={configuration.signUpLogoUrl}
              objectFit="contain"
              width={30}
              height={30}
            />
          ) : (
            <Typography color={theme.palette.text.bannerDefault}>
              {configuration.name}
            </Typography>
          )}
        </EndorsmentContainer>
      )}
      {showRecommendationStars && (
        <RatingContainer layoutAlignment={layoutAlignment}>
          <RatingStars
            totalVotes={productTotalVotes}
            showVotesNumber
            value={productRateAverage}
          />
        </RatingContainer>
      )}
      {showPartnerDescription && description && (
        <ProductDescription
          showSeeMore={showSeeMore}
          layoutAlignment={layoutAlignment}
          ref={descriptionFieldRef}
          shadow={textShadow}
        >
          {!showSeeMore && descriptionNeedsSeeMore
            ? description?.substring(0, maxNumChars).concat(" ... ")
            : description}

          {descriptionNeedsSeeMore && (
            <SeeMoreButton onClick={toggleSeeMore}>
              {!showSeeMore ? " See more" : " See less"}
            </SeeMoreButton>
          )}
        </ProductDescription>
      )}
      {showShareUrlButton && (
        <CopyToClipBoardButton
          flexOrder={-3}
          showPartnerDescription={showPartnerDescription}
          text={linkToShare}
          onCopy={() => setOpenCopiedMessage(true)}
        >
          <Tooltip
            enterTouchDelay={0}
            title={openCopiedMessage ? "Copied to clipboard" : ""}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 700 }}
            aria-label="Share" // TODO: handle translation
          >
            <ActionButtonIcon variant="contained" flexOrder={-3}>
              <ShareIconWrapper>share</ShareIconWrapper>
              <ActionButtonText>SHARE</ActionButtonText>
            </ActionButtonIcon>
          </Tooltip>
        </CopyToClipBoardButton>
      )}

      {showRedirectPartnerWebsite && website && (
        <ButtonWrapper flexOrder={-4}>
          <ActionButtonIcon
            flexOrder={-4}
            variant="contained"
            target="_blank"
            href={website}
            aria-label="Visit" // TODO: handle translation
          >
            <OpenInNewIconWrapper>open_in_new</OpenInNewIconWrapper>
            <ActionButtonText>VISIT</ActionButtonText>
          </ActionButtonIcon>
        </ButtonWrapper>
      )}
      {showIntegrationButton &&
        installNowButton &&
        item?.installNowButton?.url && (
          <ActionButton
            href={item.installNowButton.url}
            target={installNowButton.target || "_blank"}
            variant="contained"
            flexOrder={10}
            onClick={postReferral}
            aria-label={installNowButton.text}
          >
            <ActionButtonText keepInMobile>
              {installNowButton.text}
            </ActionButtonText>
          </ActionButton>
        )}
    </>
  );
};
export default TileBannerBody;
