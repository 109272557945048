import {
  CSSProperties,
  Fragment,
  FunctionComponent,
  useMemo,
  useState,
} from "react";
import { alpha, Theme, useTheme } from "@mui/material/styles";
import {
  Collapse,
  ListItem,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useUser } from "src/dataAccess/api/user";
import {
  getFontStyles,
  getJustificationByAlignment,
} from "../../builder/themeOptionsBuilder";
import type { Alignment, Orientation, Size } from "../../types/theme";
import Header from "./Header";
import Panel, { PanelVariant, usePanelColor, usePanelPadding } from "./Panel";
import { Edition, EditionPlan, Item } from "../../dataAccess/api/item";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import OnlyMobile from "./OnlyMobile";
import FeatureItem from "./FeatureItem";
import { replaceBracesKey, toNumericString } from "../../helpers/formatter";
import LoginAnchorButton from "../common/LoginAnchorButton";
import { buildUrlWithQueryParams } from "../../dataAccess/QueryParams";
import {
  getQuoteComponentFromPDP,
  useRequestQuoteButtonConfig,
} from "../public/Quote/getQuoteComponentFromPDP";
import { useConfiguration } from "../../dataAccess/api/configuration";
import type { QuoteSettings } from "../public/Quote/Quote";

interface PricingData
  extends Pick<
    Edition,
    | "benefits"
    | "buttonLabel"
    | "buttonRedirectUrl"
    | "description"
    | "items"
    | "mostPopular"
    | "title"
  > {
  amount?: number;
  currency?: string;
  frequency?: EditionPlan["frequency"] | null;
  setupFee?: number;
  contractFee?: number;
  contractMinimumServiceLength?: string;
  unit?: string;
}

export interface Props {
  item: Item;
  variant: PanelVariant;
  orientation: Orientation;
  justification: Alignment;
  headerSize: Size["size"];
  planPriceData: PricingData;
  showSeparatorLine: boolean;
  iconColor: string;
}

const JustifiedContainer = styled("div")<Pick<Props, "justification">>(
  ({ justification }) => ({
    display: "flex",
    width: "100%",
    justifyContent: getJustificationByAlignment(justification),
    alignContent: "center",
  }),
);

const PriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.8),
}));

const Description = styled(Typography)<{ haveActions: boolean }>(
  ({ theme, haveActions }) => ({
    marginBottom: haveActions ? theme.spacing(3) : 0,
    marginTop: theme.spacing(3),
  }),
);

const AmountContainer = styled("div")<Pick<Props, "justification">>(
  ({ theme, justification }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.6),
    justifyContent: getJustificationByAlignment(justification),
  }),
);

const Frequency = styled(Typography)(({ theme }) => ({
  ...getFontStyles(
    1.188,
    theme.settings.fonts.baseSize,
    theme.settings.fonts.headers.size,
  ),
  textTransform: "lowercase",
  fontWeight: 700,
}));

const Title = styled(Header)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const PricingTag = styled(Typography)<
  Pick<Props, "justification"> & {
    contained?: boolean;
    customVariant: Props["variant"];
  }
>(({ theme, contained, justification, customVariant }) => {
  const color = usePanelColor(customVariant);
  return {
    lineHeight: 1,
    fontWeight: 700,
    padding: `${theme.spacing(0.8)} ${theme.spacing(contained ? 2 : 0)}`,
    border: contained ? `1px solid ${alpha(color, 0.5)}` : "none",
    marginBottom: contained ? theme.spacing(0.5) : 0,
    borderRadius: theme.shape.borderRadius,
    ...getFontStyles(
      0.563,
      theme.settings.fonts.baseSize,
      theme.settings.fonts.headers.size,
    ),
    textTransform: "uppercase",
    alignSelf: getJustificationByAlignment(justification),
  };
});

const Amount = styled(Typography)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    lineHeight: 1,
    ...getFontStyles(
      2.938,
      theme.settings.fonts.baseSize,
      theme.settings.fonts.headers.size,
    ),
  }),
);

function buttonStyles(theme: Theme) {
  return {
    marginTop: theme.spacing(0.3),
    minWidth: 95,
  };
}

const EditionButton = styled(ThemeButton)(({ theme }) => ({
  ...buttonStyles(theme),
}));

const GatedButton = styled(LoginAnchorButton)(({ theme }) => ({
  ...buttonStyles(theme),
}));

const StyledListItem = styled(ListItem)<Pick<Props, "justification">>(
  ({ justification }) => ({
    justifyContent: getJustificationByAlignment(justification),
    textAlign: "inherit",
    padding: 0,
  }),
);

const SeeMoreButton = styled(EditionButton)<{ hasCta: boolean }>(
  ({ theme, hasCta }) => ({
    marginTop: hasCta ? theme.spacing(2) : 0,
  }),
);

const EditionContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const PricingTagContainer = styled("div")(() => ({
  display: "inherit",
  flexDirection: "inherit",
}));

const CollapseHorizontal = styled(Collapse)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    "&, & .MuiCollapse-wrapper, .MuiCollapse-wrapperInner": {
      height: "100%",
    },
    height: "auto !important",
    maxHeight: "none !important",
    opacity: `1 !important`,
    overflow: "visible",
    visibility: "visible",
  },
}));

const StyledPanel = styled(Panel)<Pick<Props, "justification">>(
  ({ justification }) => ({
    padding: `0 !important`,
    flexDirection: "column",
    textAlign: justification,
  }),
);

const Container = styled("div")<Pick<Props, "orientation">>(
  ({ orientation }) => ({
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: orientation === "horizontal" ? "flex-start" : "center",
  }),
);

const separatorLineSpacing = 2;

const Content = styled("article")<Pick<Props, "orientation" | "variant">>(({
  theme,
  orientation,
  variant,
}) => {
  const padding = usePanelPadding(variant);
  return {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding,
    [theme.breakpoints.up("md")]: {
      flexDirection: orientation === "horizontal" ? "row" : "column",
      "& > div": {
        flex: orientation === "horizontal" ? 1 : "initial",
      },
    },
  };
});

function benefitsStyles(theme: Theme): CSSProperties {
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.6),
  };
}

const BenefitsList = styled("ul")(({ theme }) => ({
  ...benefitsStyles(theme),
}));

const Benefits = styled("div")<
  Pick<Props, "showSeparatorLine"> & { hasCta: boolean }
>(({ theme, showSeparatorLine, hasCta }) => ({
  ...benefitsStyles(theme),
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(1),
  ...(showSeparatorLine
    ? {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(separatorLineSpacing),
        marginTop: hasCta ? theme.spacing(separatorLineSpacing) : 0,
        paddingBottom: theme.spacing(0),
      }
    : {}),
}));

const MostPopular = styled(Typography)<{ customVariant: Props["variant"] }>(({
  theme,
  customVariant,
}) => {
  const padding = usePanelPadding("elegant");
  return {
    background: theme.palette.primary.main,
    color: `${theme.palette.primary.contrastText}!important`,
    fontSize: theme.typography.h4.fontSize,
    marginBottom: customVariant === "plain" ? padding : 0,
    fontWeight: 700,
    minHeight: 68,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

const BenefitsHorizontal = styled(Benefits)<Pick<Props, "showSeparatorLine">>(
  ({ theme, showSeparatorLine }) => ({
    height: "100%",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      ...(showSeparatorLine
        ? {
            borderTop: 0,
            borderLeft: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(separatorLineSpacing),
            marginLeft: theme.spacing(separatorLineSpacing),
            marginTop: 0,
            paddingTop: 0,
          }
        : {}),
    },
  }),
);

const frequencyLabels: Record<NonNullable<EditionPlan["frequency"]>, string> = {
  hourly: "Hour",
  daily: "Day",
  monthly: "Month",
  quarterly: "Quarter",
  semi_annually: "6 Months",
  annually: "Year",
  biennially: "2 Years",
  triennially: "3 Years",
};

const PricingCard: FunctionComponent<Props> = ({
  item,
  variant = "sleek",
  orientation = "vertical",
  justification = "center",
  headerSize = "small",
  showSeparatorLine,
  iconColor,
  planPriceData: {
    benefits,
    buttonLabel,
    buttonRedirectUrl,
    description,
    items,
    mostPopular,
    title,
    amount,
    currency,
    contractFee,
    contractMinimumServiceLength,
    frequency,
    setupFee,
    unit,
  },
}) => {
  const theme = useTheme();
  const { user } = useUser();
  const { configuration } = useConfiguration();
  const [expanded, setExpanded] = useState(false);
  const desktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.values.md}px)`,
  );
  const horizontal = orientation === "horizontal";
  const SeeMoreContainer = horizontal ? OnlyMobile : Fragment;
  const CollapseComponent = horizontal ? CollapseHorizontal : Collapse;
  const BenefitsComponent = horizontal ? BenefitsHorizontal : Benefits;

  const noExpandedValue = desktop && horizontal ? undefined : false;
  const expandedValue =
    noExpandedValue !== undefined && expanded ? true : noExpandedValue;

  const haveSeeMore = !!items?.length || !!benefits?.length;

  const includedItems = useMemo(
    () => items.filter(({ amount, unlimited }) => Number(amount) || unlimited),
    [items],
  );

  const quoteComponentSettings = useMemo(
    () => getQuoteComponentFromPDP(configuration)?.settings as QuoteSettings,
    [configuration],
  );

  const gated =
    !user?.id || !user?.confirmed || !user?.organization?.allowedInMarketplace;

  const {
    quotesEnabledInMarketplace,
    sellerButtonEnabled,
    sellerNameReplaceConfig,
    url,
  } = useRequestQuoteButtonConfig({
    configuration,
    item,
    settings: quoteComponentSettings,
  });

  const sellerQuoteCtaEnabled =
    quotesEnabledInMarketplace && !buttonRedirectUrl && sellerButtonEnabled;

  const hasCta = !!(buttonRedirectUrl || sellerQuoteCtaEnabled);

  return (
    <Container orientation={orientation}>
      <StyledPanel variant={variant} justification={justification}>
        {mostPopular && (
          <MostPopular variant="body1" customVariant={variant}>
            Most Popular
          </MostPopular>
        )}
        <Content orientation={orientation} variant={variant}>
          <EditionContainer>
            <Title size={headerSize}>{title}</Title>
            <PriceContainer>
              <Typography>{!!amount && "Starting at"}</Typography>
              <AmountContainer justification={justification}>
                <Amount component="strong">
                  {!!amount && currency}
                  {amount ? toNumericString(amount) : "Free"}
                </Amount>
                {(unit || frequency) && (
                  <Frequency>
                    {unit && ` / ${unit}`}
                    {frequency && ` / ${frequencyLabels[frequency]}`}
                  </Frequency>
                )}
              </AmountContainer>
              {(!!contractMinimumServiceLength ||
                !!contractFee ||
                !!setupFee) && (
                <PricingTagContainer>
                  {!!contractMinimumServiceLength && (
                    <PricingTag
                      justification={justification}
                      customVariant={variant}
                      contained
                    >
                      {contractMinimumServiceLength} Month Contract
                    </PricingTag>
                  )}
                  {!!contractFee && (
                    <PricingTag
                      justification={justification}
                      customVariant={variant}
                    >
                      {currency}
                      {toNumericString(contractFee)} Contract Fee
                    </PricingTag>
                  )}
                  {!!setupFee && (
                    <PricingTag
                      justification={justification}
                      customVariant={variant}
                    >
                      {currency}
                      {toNumericString(setupFee)} Setup Fee
                    </PricingTag>
                  )}
                </PricingTagContainer>
              )}
            </PriceContainer>
            {description && (
              <Description haveActions={hasCta || haveSeeMore}>
                {description}
              </Description>
            )}
            {hasCta && (
              <JustifiedContainer justification={justification}>
                {!!buttonRedirectUrl && (
                  <EditionButton href={buttonRedirectUrl} target="_blank">
                    {buttonLabel}
                  </EditionButton>
                )}
                {sellerQuoteCtaEnabled &&
                  (gated ? (
                    <GatedButton>
                      {quoteComponentSettings.labels?.gated}
                    </GatedButton>
                  ) : (
                    <EditionButton
                      href={buildUrlWithQueryParams(url, {
                        created_for: "seller",
                      })}
                    >
                      {replaceBracesKey(
                        quoteComponentSettings.labels?.forSellers?.request,
                        sellerNameReplaceConfig,
                      )}
                    </EditionButton>
                  ))}
              </JustifiedContainer>
            )}
          </EditionContainer>
          {haveSeeMore && (
            <>
              <CollapseComponent in={expanded} aria-expanded={expandedValue}>
                <BenefitsComponent
                  showSeparatorLine={showSeparatorLine}
                  hasCta={hasCta}
                >
                  {!!includedItems?.length && (
                    <FeatureItem
                      title="Items"
                      hideIcon
                      justification={justification}
                    >
                      <ul>
                        {includedItems.map(
                          ({ amount, unit, unlimited }, index) => (
                            <StyledListItem
                              key={index}
                              justification={justification}
                            >
                              <Typography>
                                {unlimited ? "Unlimited" : Number(amount)}{" "}
                                {unit}
                                {(unlimited || Number(amount) > 1) && "(s)"}
                              </Typography>
                            </StyledListItem>
                          ),
                        )}
                      </ul>
                    </FeatureItem>
                  )}
                  {!!benefits?.length && (
                    <BenefitsList>
                      {benefits.map(({ tooltip, content }, index) => (
                        <StyledListItem
                          key={index}
                          justification={justification}
                        >
                          <FeatureItem
                            title={tooltip}
                            iconColor={iconColor}
                            justification={justification}
                          >
                            <Typography>{content}</Typography>
                          </FeatureItem>
                        </StyledListItem>
                      ))}
                    </BenefitsList>
                  )}
                </BenefitsComponent>
              </CollapseComponent>
              <SeeMoreContainer>
                <JustifiedContainer justification={justification}>
                  <SeeMoreButton
                    customVariant="secondary"
                    onClick={() => setExpanded((prev) => !prev)}
                    hasCta={hasCta || (!hasCta && expanded)}
                  >
                    {expanded ? "See less" : "See more"}
                  </SeeMoreButton>
                </JustifiedContainer>
              </SeeMoreContainer>
            </>
          )}
        </Content>
      </StyledPanel>
    </Container>
  );
};

export default PricingCard;
