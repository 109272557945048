import { Dialog, Icon, Paper, styled } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { CloseButton } from "./CloseButton";

interface Props {
  renderClickable: (open: () => void) => React.ReactNode;
  children: React.ReactNode;
}

const spacing = 20;

const StyledPaper = styled(Paper)(({ theme }) => {
  const padding = spacing / 4;
  return {
    background: theme.palette.background.default,
    position: "relative",
    height: "100vh",
    maxHeight: "100vh !important",
    padding: `0 ${padding}rem`,
    margin: "0 !important",
    borderRadius: "0",
    overflow: "hidden !important",
    width: "100vw",
    maxWidth: "100vw !important",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      maxWidth: `100vw !important`,
    },
  };
});

const PreviewDialogButton: FunctionComponent<Props> = ({
  children,
  renderClickable,
}: Props) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  return (
    <>
      {renderClickable(() => setOpen(true))}
      <Dialog open={open} onClose={close} PaperComponent={StyledPaper}>
        <CloseButton onClick={close}>
          <Icon>close</Icon>
        </CloseButton>
        {children}
      </Dialog>
    </>
  );
};

export default PreviewDialogButton;
