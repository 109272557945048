import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey } from "@mui/material/colors";
import SectionTitle from "./SectionTitle";

export interface CollapsableSectionSettings {
  collapseSection?: boolean;
}

interface Props {
  isCollapsable?: boolean;
  title: string;
  id: string;
  children: React.ReactNode;
}

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  borderBottom: `1px solid ${grey[400]}`,
  borderRadius: 0,
}));

const CollapsableSection = ({ id, isCollapsable, title, children }: Props) =>
  isCollapsable ? (
    <StyledAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
        <SectionTitle spacing="none">{title}</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  ) : (
    <>{children}</>
  );

export default CollapsableSection;
