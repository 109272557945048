import { FunctionComponent } from "react";
import MoreInformationSection from "../ui/MoreInformationSection";
import { ChildrenProps as Props } from "./MoreInformation";

export function displayHelpLinks(item: Props["item"]) {
  return !!item?.helpfulLinks?.length;
}

const HelpLinks: FunctionComponent<Props> = ({ item, parentComponent }) =>
  item && displayHelpLinks(item) ? (
    <MoreInformationSection
      variant="link"
      items={
        item.helpfulLinks?.map(({ text: label, url }) => ({ label, url })) || []
      }
      title="Help Links"
      config={parentComponent?.settings}
    />
  ) : (
    <></>
  );

export default HelpLinks;
