import { FunctionComponent } from "react";
import { styled, Typography } from "@mui/material";
import SectionTitle from "../ui/SectionTitle";
import { Item } from "../../dataAccess/api/item";
import { PublicComponentProps } from "./PublicComponentProps";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";

export interface Settings extends CollapsableSectionSettings {
  title: string;
}

interface Props extends PublicComponentProps<Settings> {
  item?: Item;
}

const Main = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const StyledContentText = styled(Typography)(() => ({
  whiteSpace: "pre-wrap",
}));

const HowItWorks: FunctionComponent<Props> = ({
  settings: { title, collapseSection },
  item,
}: Props) =>
  item?.howItWorks ? (
    <Main>
      <CollapsableSection
        isCollapsable={collapseSection}
        id="how-it-works-accordion"
        title="How it Works"
      >
        {!collapseSection && <SectionTitle>{title}</SectionTitle>}
        <StyledContentText>{item.howItWorks}</StyledContentText>
      </CollapsableSection>
    </Main>
  ) : (
    <></>
  );

export default HowItWorks;
