import { FunctionComponent, useMemo } from "react";
import { Link, styled } from "@mui/material";
import PricingCard from "../ui/PricingCard";
import Carousel from "../ui/Carousel";
import SectionTitle from "../ui/SectionTitle";
import type { EditionCost, EditionPlan, Item } from "../../dataAccess/api/item";
import type { PanelVariant } from "../ui/Panel";
import type { Alignment, Orientation, Size } from "../../types/theme";
import type { PublicComponentProps } from "./PublicComponentProps";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";

interface Settings extends CollapsableSectionSettings {
  numberOfSlidesOnDesktop: "1" | "2" | "3" | "4";
  variant: PanelVariant;
  justification: Alignment;
  orientation: Orientation;
  headerSize: Size["size"];
  showSeparatorLine: boolean;
  iconColor: string;
}
interface Props extends PublicComponentProps<Settings> {
  item?: Item;
}

const PriceCardContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

const LinkForPricing = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.body2.fontSize,
  fontFamily: theme.typography.body2.fontFamily,
}));

export function mapLowestPlan(plans: EditionPlan[]) {
  if (!plans?.length) {
    return;
  }

  const freePlan = plans.find((plan) => plan.revenueType === "free");
  if (freePlan) {
    return;
  }

  const computedPlans = plans.map((plan) => {
    let planCost: EditionCost | null = null;
    let oneTimeSetupCost: EditionCost | null = null;
    let notApplicableCost: EditionCost | null = null;

    if (plan.costs?.length) {
      const { baseUnitsCosts, notApplicableCosts, oneTimeCosts } =
        plan.costs.reduce<{
          baseUnitsCosts: EditionCost[];
          notApplicableCosts: EditionCost[];
          oneTimeCosts: EditionCost[];
        }>(
          (acc, cost) => {
            if (cost.unit === "ONE_TIME_SETUP") {
              acc.oneTimeCosts.push(cost);
            } else if (cost.unit === "NOT_APPLICABLE") {
              acc.notApplicableCosts.push(cost);
            } else {
              acc.baseUnitsCosts.push(cost);
            }
            return acc;
          },
          { baseUnitsCosts: [], notApplicableCosts: [], oneTimeCosts: [] },
        );

      if (baseUnitsCosts?.length) {
        planCost = baseUnitsCosts.reduce((prev, curr) => {
          const prevMin = prev.minUnits !== undefined ? prev.minUnits : 0;
          const currMin = curr.minUnits !== undefined ? curr.minUnits : 0;
          return currMin < prevMin ? curr : prev;
        });
      }

      [notApplicableCost] = notApplicableCosts;

      [oneTimeSetupCost] = oneTimeCosts;
    }

    const notApplicableAmount = notApplicableCost?.amount
      ? parseFloat(notApplicableCost.amount)
      : 0;

    const oneTimeSetupAmount = oneTimeSetupCost?.amount
      ? parseFloat(oneTimeSetupCost.amount)
      : 0;

    const lowerBaseCostAmount = planCost?.amount
      ? parseFloat(planCost.amount)
      : 0;

    const basicMainAmount = lowerBaseCostAmount + notApplicableAmount;

    const contractAmount = plan.contract?.amount
      ? parseFloat(plan.contract.amount)
      : 0;

    const tieBreakerAmount =
      // This is in case a plan tie in cost
      basicMainAmount + oneTimeSetupAmount + contractAmount;

    return {
      plan,
      basicMainAmount: basicMainAmount || oneTimeSetupAmount,
      frequency: basicMainAmount ? plan?.frequency : null,
      baseCurrency:
        notApplicableCost?.currency ||
        oneTimeSetupCost?.currency ||
        planCost?.currency,
      planCost,
      oneTimeSetupCost,
      tieBreakerAmount,
    };
  });

  computedPlans.sort((a, b) => {
    if (a.basicMainAmount !== b.basicMainAmount) {
      return a.basicMainAmount - b.basicMainAmount;
    }
    return a.tieBreakerAmount - b.tieBreakerAmount;
  });

  const [first] = computedPlans;

  return {
    contract: first?.plan?.contract,
    basicMainAmount: first?.basicMainAmount,
    frequency: first?.frequency,
    cost: first.planCost,
    baseCurrency: first.baseCurrency,
    oneTimeSetupCost: first.oneTimeSetupCost,
  };
}

const Pricing: FunctionComponent<Props> = ({ settings, item }) => {
  const slidesPerViewOnDesktop = useMemo(
    () =>
      Math.min(
        settings?.orientation === "horizontal"
          ? Math.min(Number(settings?.numberOfSlidesOnDesktop), 2)
          : Number(settings?.numberOfSlidesOnDesktop),
        item?.listingEditions.length || 0,
      ) || 1,
    [item, settings],
  );
  return item?.listingEditions && item?.listingEditions.length > 0 ? (
    <section>
      <CollapsableSection
        isCollapsable={settings?.collapseSection}
        id="pricing-accordion"
        title="Pricing"
      >
        <HeaderContainer>
          {!settings?.collapseSection && <SectionTitle>Pricing</SectionTitle>}
          {item?.pricingLink && (
            <LinkForPricing href={item.pricingLink} target="_blank">
              See all pricing
            </LinkForPricing>
          )}
        </HeaderContainer>
        <Carousel
          rowsPerView={1}
          slidesPerViewOnDesktop={slidesPerViewOnDesktop}
          fullWidthSwiper
        >
          {item.listingEditions.map(
            (
              {
                benefits,
                buttonLabel,
                buttonRedirectUrl,
                description,
                items,
                mostPopular,
                plans,
                title,
              },
              idx,
            ) => {
              const {
                baseCurrency,
                basicMainAmount,
                contract,
                cost,
                frequency,
                oneTimeSetupCost,
              } = mapLowestPlan(plans) || {};
              return (
                <PriceCardContainer key={idx}>
                  <PricingCard
                    item={item}
                    planPriceData={{
                      benefits,
                      buttonLabel,
                      buttonRedirectUrl,
                      description,
                      items,
                      mostPopular,
                      title,
                      amount: basicMainAmount,
                      currency: baseCurrency,
                      frequency,
                      setupFee: Number(oneTimeSetupCost?.amount),
                      contractFee: Number(contract?.amount),
                      contractMinimumServiceLength:
                        contract?.minimumServiceLength,
                      unit: cost?.unit,
                    }}
                    {...settings}
                  />
                </PriceCardContainer>
              );
            },
          )}
        </Carousel>
      </CollapsableSection>
    </section>
  ) : (
    <></>
  );
};

export default Pricing;
