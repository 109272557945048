import { Typography, styled } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  buildUrlWithQueryParams,
  removeQueryParams,
  useQueryParams,
} from "../../../dataAccess/QueryParams";
import { EditorCtx } from "../../../wysiwyg/EditorCtx";
import { Item } from "../../../dataAccess/api/item";
import { PublicComponentProps } from "../PublicComponentProps";
import { useConfiguration } from "../../../dataAccess/api/configuration";
import { useUser } from "../../../dataAccess/api/user";
import SkeletonLoader from "../../ui/SkeletonLoader";
import {
  ActionsContainer,
  ActionsWrapper,
  Content,
  DescriptionText,
  InnerContainer,
  LoadingContainer,
  Main,
  StyledBox,
  StyledButton,
  StyledCustomContainer,
  StyledDivider,
  StyledName,
} from "../../ui/DealLikeComponents";
import LoginAnchorButton from "../../common/LoginAnchorButton";
import type { Alignment, Size } from "../../../types/theme";
import TextAlert from "../../ui/TextAlert";
import type { PanelVariant } from "../../ui/Panel";
import { replaceBracesKey } from "../../../helpers/formatter";
import { PreviewBannerCtx } from "../../ui/PreviewBanner";
import { useRequestQuoteButtonConfig } from "./getQuoteComponentFromPDP";

interface Settings {
  alignment: Alignment;
  labels: {
    gated: string;
    history: string;
    forSellers: {
      request: string;
      successMessage: string;
    };
    forPartners: {
      request: string;
      successMessage: string;
    };
  };
  variant: PanelVariant;
  headerSize: Size["size"];
  enabledQuoteRecipient: "seller" | "partner" | "both";
}

interface Props extends PublicComponentProps<Settings> {
  item?: Item;
  loadingItem?: boolean;
  quoteHistoryAvailable?: boolean;
  loadingQuoteHistoryAvailable?: boolean;
}

const NameContainer = styled("div")(() => ({
  marginBottom: "1.2rem",
}));

const StyledTextAlert = styled(TextAlert)(() => ({
  marginBottom: 0,
}));

const Quote = ({
  item,
  loadingItem,
  settings,
  quoteHistoryAvailable,
  loadingQuoteHistoryAvailable,
}: Props) => {
  const { configuration } = useConfiguration();
  const { user: realUser, loadingUser } = useUser();
  const { mocks } = useContext(EditorCtx);
  const { created_for: createdForParam, preview_action } = useQueryParams();
  const { setShowPreviewRedeemBanner } = useContext(PreviewBannerCtx);
  const [createdFor] = useState(createdForParam);

  let user = realUser;
  if (mocks && "noUserMock" in mocks) {
    user = mocks?.noUserMock;
  }

  const { quoteSetting: quote } = item || {};

  const gatedButtonText = settings.labels?.gated;
  const loading = loadingItem || loadingUser;

  const replaceVarValuesPartner = useMemo(
    () => ({
      key: "partner_name",
      value: configuration?.managerOrganization?.name,
    }),
    [configuration?.managerOrganization?.name],
  );

  useEffect(() => {
    if (preview_action === "quote_submit") {
      removeQueryParams(["preview_action", "created_for"]);
      setShowPreviewRedeemBanner(true);
    }
  }, []);

  const {
    sellerButtonEnabled,
    partnerButtonEnabled,
    sellerNameReplaceConfig: replaceVarValuesSeller,
    quotesEnabledInMarketplace,
    url,
  } = useRequestQuoteButtonConfig({ settings, item, configuration });

  const actionSectionResult = useMemo(() => {
    if (!user?.id) {
      return (
        <StyledCustomContainer alignment={settings.alignment}>
          <LoginAnchorButton>{gatedButtonText}</LoginAnchorButton>
        </StyledCustomContainer>
      );
    }

    if (!user?.confirmed) {
      return (
        <TextAlert
          alignment={settings.alignment}
          icon={<Icon>warning</Icon>}
          color="warning"
        >
          Pending Email verification. Please go to your email and verify your
          account
        </TextAlert>
      );
    }

    if (!user?.organization?.allowedInMarketplace) {
      return (
        <TextAlert
          alignment={settings.alignment}
          icon={<Icon>warning</Icon>}
          color="warning"
        >
          You&apos;re pending approval. Expect an email when you are approved by{" "}
          {configuration?.managerOrganization?.name}
        </TextAlert>
      );
    }

    return (
      <ActionsWrapper alignment={settings.alignment}>
        <ActionsContainer
          redemptionActionsLayout="normal"
          variant={settings.variant}
        >
          {sellerButtonEnabled && (
            <StyledButton
              href={buildUrlWithQueryParams(url, { created_for: "seller" })}
            >
              {replaceBracesKey(
                settings.labels?.forSellers?.request,
                replaceVarValuesSeller,
              )}
            </StyledButton>
          )}
          {partnerButtonEnabled && (
            <StyledButton
              href={buildUrlWithQueryParams(url, { created_for: "partner" })}
              customVariant={sellerButtonEnabled ? "secondary" : undefined}
            >
              {replaceBracesKey(
                settings.labels?.forPartners?.request,
                replaceVarValuesPartner,
              )}
            </StyledButton>
          )}
          {!loadingQuoteHistoryAvailable && !!quoteHistoryAvailable && (
            <StyledButton href={url} variant="text">
              {settings.labels?.history}
            </StyledButton>
          )}
        </ActionsContainer>
      </ActionsWrapper>
    );
  }, [
    gatedButtonText,
    settings,
    user,
    configuration?.managerOrganization?.name,
    item,
    replaceVarValuesPartner,
    replaceVarValuesSeller,
    quoteHistoryAvailable,
    loadingQuoteHistoryAvailable,
    partnerButtonEnabled,
    sellerButtonEnabled,
    url,
  ]);

  if (!quotesEnabledInMarketplace) {
    return null;
  }

  return (
    <Main variant={settings?.variant}>
      <InnerContainer>
        {loading ? (
          <LoadingContainer>
            <SkeletonLoader variant="text" width="30%" />
            <div>
              <SkeletonLoader variant="text" />
              <SkeletonLoader variant="text" />
            </div>
            <SkeletonLoader variant="text" />
            <SkeletonLoader variant="text" />
          </LoadingContainer>
        ) : (
          <>
            {quote?.title && (
              <NameContainer>
                <StyledName size={settings.headerSize}>
                  {quote.title}
                </StyledName>
              </NameContainer>
            )}
            <Content>
              {quote?.description && (
                <DescriptionText>{quote.description}</DescriptionText>
              )}
              {quote?.instructions && (
                <Typography>
                  <>
                    <StyledBox fontWeight={700} display="inline">
                      Instructions:
                    </StyledBox>{" "}
                    {quote.instructions}
                  </>
                </Typography>
              )}
              {/* TODO: keeping this StyledDivider to have style consistency, temporary until we have resolution
          of showSeparatorLane setting in deal component */}
              <StyledDivider showSeparatorLane={false} />
            </Content>
          </>
        )}
        {actionSectionResult}

        {createdFor && (
          <StyledTextAlert
            alignment={settings.alignment}
            icon={<Icon>done</Icon>}
            color="success"
          >
            {createdFor === "seller" &&
              replaceBracesKey(
                settings.labels?.forSellers?.successMessage,
                replaceVarValuesSeller,
              )}
            {createdFor === "partner" &&
              replaceBracesKey(
                settings.labels?.forPartners?.successMessage,
                replaceVarValuesPartner,
              )}
          </StyledTextAlert>
        )}
      </InnerContainer>
    </Main>
  );
};

export type QuoteSettings = Settings;
export default Quote;
