import { FunctionComponent } from "react";
import {
  Icon,
  Rating,
  SelectChangeEvent,
  Typography,
  styled,
} from "@mui/material";
import { Review } from "../../../dataAccess/api/review";
import Carousel from "../Carousel";
import ReviewCard from "./ReviewCard";
import DropdownList, { DropdownItem } from "../DropdownList";
import { Settings } from "../../public/Reviews";
import { TextButton } from "../TextButton";

interface Props extends Settings {
  reviews: Review[];
  setSelectedFilterOption: (option: DropdownItem) => void;
  selectedFilterOption: DropdownItem;
  setSelectedSortOption: (option: DropdownItem) => void;
  selectedSortOption: DropdownItem;
}

const FlexContainer = styled("div")(() => ({
  display: "flex",
}));

const EmptyStateContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ArrowUpIcon = styled(Icon)(({ theme }) => ({
  width: "1rem",
  fill: theme.palette.text.paper,
}));

const ArrowDownIcon = styled(Icon)(({ theme }) => ({
  width: "1rem",
  fill: theme.palette.text.paper,
}));

export const StyledTextButton = styled(TextButton)(() => ({
  lineHeight: 0,
  padding: 0,
}));

const sortOptions: DropdownItem[] = [
  {
    value: "highest",
    label: "Highest",
    icon: <ArrowUpIcon>arrow_up</ArrowUpIcon>,
  },
  {
    value: "lowest",
    label: "Lowest",
    icon: <ArrowDownIcon>arrow_down</ArrowDownIcon>,
  },
];

const filterOptions: DropdownItem[] = [
  {
    value: "1",
    label: "Stars",
    icon: <Rating readOnly value={1} />,
  },
  {
    value: "2",
    label: "Stars",
    icon: <Rating readOnly value={2} />,
  },
  {
    value: "3",
    label: "Stars",
    icon: <Rating readOnly value={3} />,
  },
  {
    value: "4",
    label: "Stars",
    icon: <Rating readOnly value={4} />,
  },
  {
    value: "5",
    label: "Stars",
    icon: <Rating readOnly value={5} />,
  },
];

const ReviewsList: FunctionComponent<Props> = ({
  showSortOptions,
  showFilterOptions,
  reviewsShown,
  showRating,
  showReviewerName,
  reviews,
  variant = "plain",
  selectedFilterOption,
  setSelectedFilterOption,
  selectedSortOption,
  setSelectedSortOption,
}) => {
  const handleSortChange = (event: SelectChangeEvent<DropdownItem>) => {
    const currentSortOption = sortOptions.find(
      (option) => option.value === event.target.value,
    );
    if (!currentSortOption) return;

    setSelectedSortOption(currentSortOption);
  };

  const handleFilterChange = (event: SelectChangeEvent<DropdownItem>) => {
    const currentFilterOption = filterOptions.find(
      (option) => option.value === event.target.value,
    );
    if (!currentFilterOption) return;

    setSelectedFilterOption(currentFilterOption);
  };

  return (
    <>
      <FlexContainer>
        {showSortOptions && (
          <DropdownList
            selectedValue={selectedSortOption}
            onChange={handleSortChange}
            options={sortOptions}
            label="Sort"
            icon={<Icon>keyboard_arrow_down</Icon>}
          />
        )}
        {showFilterOptions && showRating && (
          <DropdownList
            selectedValue={selectedFilterOption}
            onChange={handleFilterChange}
            options={filterOptions}
            label="Filter"
            icon={<Icon>keyboard_arrow_down</Icon>}
          />
        )}
        {(showFilterOptions || showSortOptions) && (
          <TextButton
            onClick={() => {
              setSelectedFilterOption({
                value: "",
                label: "",
              });
              setSelectedSortOption({
                value: "",
                label: "",
              });
            }}
          >
            Clear Filters
          </TextButton>
        )}
      </FlexContainer>
      {reviews && reviews.length > 0 ? (
        <Carousel
          rowsPerView={reviewsShown}
          slidesPerViewOnDesktop={1}
          fullWidthSwiper
        >
          {reviews?.map((item, idx) => (
            <ReviewCard
              showRating={showRating}
              showReviewerName={showReviewerName}
              review={item}
              key={idx}
              variant={reviewsShown > 1 ? "plain" : variant}
              imageSize={reviewsShown > 1 ? 80 : 160}
            />
          ))}
        </Carousel>
      ) : (
        <EmptyStateContainer>
          <Typography variant="h5">There are not reviews yet</Typography>
          <Typography>
            Be the first on sharing a review with our community!
          </Typography>
        </EmptyStateContainer>
      )}
    </>
  );
};

export default ReviewsList;
