import { Link, styled } from "@mui/material";
import { FunctionComponent } from "react";
import FacebookIcon from "../../assets/svg/facebook-logo.svg";
import LinkedinIcon from "../../assets/svg/linkedin-logo.svg";
import InstagramIcon from "../../assets/svg/instagram-logo.svg";
import TwitterIcon from "../../assets/svg/twitter-logo.svg";
import { ChildrenProps as Props } from "./MoreInformation";
import Panel from "../ui/Panel";
import {
  Main,
  MoreInformationSectionTitle,
} from "../ui/MoreInformationSection";

const ImageContainer = styled(Link)(() => ({
  marginRight: "0.5rem",
  cursor: "pointer",
}));

export function displaySocialLinks(item: Props["item"]): boolean {
  const { facebook, instagram, twitter, linkedin } = item?.seller ?? {};
  return !!(facebook || instagram || twitter || linkedin);
}

const SocialLinks: FunctionComponent<Props> = ({ item, parentComponent }) => {
  const { facebook, instagram, twitter, linkedin } = item?.seller ?? {};

  const [facebookUrl, instagramUrl, twitterUrl, linkedinUrl] = [
    facebook,
    instagram,
    twitter,
    linkedin,
  ].map((social) =>
    !social || /https?:\/\//.test(social) ? social : `https://${social}`,
  );

  return displaySocialLinks(item) ? (
    <Main config={parentComponent?.settings}>
      <MoreInformationSectionTitle
        variant="h3"
        settings={parentComponent?.settings}
      >
        Socials
      </MoreInformationSectionTitle>
      <Panel variant={parentComponent?.settings.variant}>
        {facebookUrl && (
          <ImageContainer
            href={facebookUrl}
            target="_blank"
            aria-label="Facebook"
          >
            <FacebookIcon alt="Logo Facebook" width={40} height={40} />
          </ImageContainer>
        )}
        {linkedinUrl && (
          <ImageContainer
            href={linkedinUrl}
            target="_blank"
            aria-label="LinkedIn"
          >
            <LinkedinIcon alt="Logo LinkedIn" width={40} height={40} />
          </ImageContainer>
        )}
        {instagramUrl && (
          <ImageContainer
            href={instagramUrl}
            target="_blank"
            aria-label="Instagram"
          >
            <InstagramIcon alt="Logo Instagram" width={40} height={40} />
          </ImageContainer>
        )}
        {twitterUrl && (
          <ImageContainer href={twitterUrl} target="_blank" aria-label="X">
            <TwitterIcon alt="Logo X" width={40} height={40} />
          </ImageContainer>
        )}
      </Panel>
    </Main>
  ) : (
    <></>
  );
};

export default SocialLinks;
