import { FunctionComponent } from "react";
import { styled } from "@mui/material";
import type { Configuration } from "src/dataAccess/api/configuration";
import type { Item as ProductItem } from "../../dataAccess/api/item";
import Carousel from "../ui/Carousel";
import Tile from "../ui/Tile";
import SectionTitle from "../ui/SectionTitle";
import type { Item } from "../../dataAccess/api/items";
import { PublicComponentProps } from "./PublicComponentProps";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";
import type { ThemeComponent } from "../../wysiwyg/JsonThemeObject";

interface Settings extends CollapsableSectionSettings {
  similarItemsBy?: "collection" | "category";
}

interface Props extends PublicComponentProps<Settings> {
  similarItems?: Item[];
  item?: ProductItem;
}

const Main = styled("div")(() => ({
  width: "100%",
}));

const TileContainer = styled("div")(() => ({
  width: "13rem",
  height: "100%",
}));

const SimilarTo: FunctionComponent<Props> = ({
  similarItems,
  item,
  settings,
}: Props) => {
  if (similarItems && similarItems.length > 0) {
    const title = item ? `Similar To ${item.seller.name}` : "";
    return (
      <div>
        <CollapsableSection
          isCollapsable={settings?.collapseSection}
          id="similar-to"
          title={title}
        >
          <Main>
            {title && !settings?.collapseSection && (
              <SectionTitle>{title}</SectionTitle>
            )}
            <Carousel>
              {similarItems.map((discount) => (
                <TileContainer key={discount.id}>
                  <Tile item={discount} loading={false} />
                </TileContainer>
              ))}
            </Carousel>
          </Main>
        </CollapsableSection>
      </div>
    );
  }
  return <></>;
};

export default SimilarTo;

export function getSimilarToComponentFromPDP(configuration?: Configuration) {
  // TODO: considering the increse in the amount of dependencies we have on specific component settings,
  // we should add a standard way to search and retrieve components from configuration
  return configuration?.publishedTemplate?.properties?.pages
    .find(({ id }: { id: string }) => id === "PDP")
    ?.components?.find(({ type }) => type === "DealDetailsLayout")
    ?.components?.find(
      ({ type }) => type === "SimilarTo",
    ) as ThemeComponent<Settings>;
}
