import { Alert, Box, Divider, styled, Typography } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import type { DealSettings as Settings } from "../public/Deal";
import Panel from "./Panel";
import Header from "./Header";

/**
 * This file is needed unless we refactor Deal component and Quote component
 */

export const Main = styled(Panel)(() => ({
  width: "100%",
  marginBottom: "2rem",
}));

export const InnerContainer = styled("div")(() => ({
  width: "100%",
}));

export const LoadingContainer = styled(Main)(() => ({
  gap: "1.2rem",
}));

export const Content = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.2rem",
}));

export const StyledCustomContainer = styled("div")<{
  alignment?: Settings["alignment"];
}>(({ alignment }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  alignItems: "center",
  justifyContent: getJustificationByAlignment(alignment),
}));

export const StyledName = styled(Header)(() => ({
  display: "inline-block",
}));

export const ActionsWrapper = styled("div")<Pick<Settings, "alignment">>(
  ({ alignment }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: getJustificationByAlignment(alignment),
    width: "100%",
  }),
);

export const ActionsContainer = styled("div")<
  Pick<Settings, "redemptionActionsLayout" | "variant">
>(({ redemptionActionsLayout, variant, theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: redemptionActionsLayout === "inverted" ? "row-reverse" : "row",
  gap: "1rem",
  flexWrap: "wrap",
  paddingBottom: variant === "plain" ? theme.spacing(2) : 0,
}));

export const InlineAlert = styled(Alert)(({ theme }) => ({
  display: "inline-flex",
  background: "none",
  padding: "0 !important",
  float: "right",
  width: "100%",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    width: "unset",
  },
}));

export const NameContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  columnGap: "1rem",
  marginBottom: "0.5rem",
}));

export const FlexTransitionGroup = styled(TransitionGroup)<
  Pick<Settings, "redemptionActionsLayout">
>(({ redemptionActionsLayout }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  paddingBottom: "1rem",
  flexDirection: redemptionActionsLayout === "inverted" ? "row-reverse" : "row",
}));

export const StyledButton = styled(ThemeButton)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "unset",
  },
}));

export const StyledBox = styled(Box)(() => ({
  textDecoration: "underline",
}));

export const DescriptionText = styled(Typography)({
  whiteSpace: "pre-line",
});

export const StyledDivider = styled(Divider)<{
  showSeparatorLane: boolean;
}>(({ showSeparatorLane }) => ({
  visibility: showSeparatorLane ? "visible" : "hidden",
  marginTop: "1.5rem",
  borderColor: "#F7F8FA",
  marginBottom: "1.5rem",
}));

export const animationTimeout = 300;
