import { Alert, Fade, styled, Typography, useTheme } from "@mui/material";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";
import { ReactNode, useContext, useEffect, useState } from "react";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import { JSONAPIServerError } from "../../dataAccess/JSONAPIServerError";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import type { Alignment, MuiColor, Size } from "../../types/theme";
import { Discount, Item, RedemptionType } from "../../dataAccess/api/item";
import { PublicComponentProps } from "./PublicComponentProps";
import { Subcomponents as DealDetailsLayoutSubcomponents } from "./DealDetailsLayout";
import DealForm from "../ui/DealForm";
import { ErrorCode, ServerError } from "../../dataAccess/ServerError";
import {
  RedemptionFlow,
  useConfiguration,
} from "../../dataAccess/api/configuration";
import { useUser } from "../../dataAccess/api/user";
import { BrowserTab, useNavigateNewTabSync } from "../../helpers/navigation";
import {
  GuestFlowRedemption,
  postContactSupport,
  postRedemption,
  postResendEmail,
  Redemption,
} from "../../dataAccess/api/deal";
import SkeletonLoader from "../ui/SkeletonLoader";
import EarningsBox from "../ui/EarningsBox";
import {
  createGuest,
  isGuestUser,
  resendGuestUserVerification,
} from "../../dataAccess/api/guestUser";
import { splitFullName } from "../../helpers/formatter";
import { lastDealKey } from "../pages/PDP";
import { ButtonProgress } from "../ui/ButtonProgress";
import { PreviewBannerCtx } from "../ui/PreviewBanner";
import LoginAnchorButton from "../common/LoginAnchorButton";
import {
  ActionsContainer,
  ActionsWrapper,
  animationTimeout,
  Content,
  DescriptionText,
  FlexTransitionGroup,
  InlineAlert,
  InnerContainer,
  LoadingContainer,
  Main,
  NameContainer,
  StyledBox,
  StyledButton,
  StyledCustomContainer,
  StyledDivider,
  StyledName,
} from "../ui/DealLikeComponents";
import TextAlert from "../ui/TextAlert";
import type { PanelVariant } from "../ui/Panel";

enum RedemptionState {
  ErrorInConfiguration,
  Loading,
  EmailFlow,
  OtherFlow,
  LoginRequired,
  EmailFormFlow,
}

const SellerNameDynamicKey = "{seller_name}";

interface Settings {
  redemptionActionsLayout: "normal" | "inverted";
  alignment: Alignment;
  savingAmountColor: string;
  newCustomersOnlyColor: string;
  newAndExistingCustomersColor: string;
  showSeparatorLine: boolean;
  variant: PanelVariant;
  headerSize: Size["size"];
  redemptionButtons: {
    emailRedemptions: {
      gated: string;
      redemption: string;
      postRedemption: string;
      successMessage: string;
      getApproval: string;
      resendApproval: string;
      claimNow: string;
    };
    urlRedemptions: {
      gated: string;
      redemption: string;
      postRedemption: string;
    };
    supportMessage: string;
    hideSupportButton: boolean;
  };
}

// TODO: remove "any" type in the next lines
interface Props
  extends PublicComponentProps<Settings, any, DealDetailsLayoutSubcomponents> {
  item?: Item;
  lastDealId?: string;
  loadingItem?: boolean;
}

const StyledFlexContainer = styled("div")(() => ({
  display: "flex",
}));

const StyledAverageSavings = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  color: theme.palette.success.main,
  marginBottom: "1.2rem",
}));

type ValidationMessage = { color?: string };

const SuccessMessage = styled(Typography)<ValidationMessage>(
  ({ theme, color }) => ({
    color: color || theme.palette.success.main,
  }),
);

const WarningMessage = styled(Typography)<ValidationMessage>(
  ({ theme, color }) => ({
    color: color || theme.palette.warning.main,
  }),
);

const StatusIconContainer = styled("div")(() => ({
  marginRight: "0.8rem",
}));

const StyledRedemptionStatus = styled(Typography)<Pick<Settings, "alignment">>(
  ({ alignment }) => ({
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginTop: "1.5rem",
    gap: ".2rem",
    marginBottom: "1.5rem",
    justifyContent: getJustificationByAlignment(alignment),
  }),
);

const StyledAlert = styled(Alert)(() => ({
  marginBottom: "1rem",
}));

const CenterContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: "1rem",
}));

const StyledSkeletonRectangular = styled(SkeletonLoader)(() => ({
  height: "5rem",
}));

const DefaultMessage = styled("span")(() => ({
  marginLeft: ".5rem",
  fontWeight: "bold",
}));

const NotificationMessage = styled(DefaultMessage)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const UpgradedMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.caption.fontSize,
  display: "inline-block",
}));

const BlurredBox = styled("div")(() => ({
  filter: "blur(4px)",
  pointerEvents: "none",
  userSelect: "none",
}));

const FormContainer = styled("div")(() => ({
  display: "flex",
  gap: "2rem",
  flexDirection: "column",
}));

const CustomColorIcon = styled(Icon)<{
  defaultColor: MuiColor;
  customColor?: string;
}>(({ theme, customColor, defaultColor }) => ({
  color: customColor || theme.palette[defaultColor]?.main,
}));

function useRedemtionFlow(): { state: RedemptionState } {
  const { user: realUser, loadingUser } = useUser();
  const { mocks } = useContext(EditorCtx);
  let user = realUser;
  if (mocks && "noUserMock" in mocks) {
    user = mocks?.noUserMock;
  }
  const { configuration, loadingConfiguration } = useConfiguration();

  if (configuration instanceof ServerError) {
    return { state: RedemptionState.ErrorInConfiguration };
  }

  if (loadingUser || loadingConfiguration) {
    return { state: RedemptionState.Loading };
  }

  if (configuration?.forceAccounts) {
    if (user?.id) {
      if (configuration?.redemptionFlow === RedemptionFlow.Email) {
        return { state: RedemptionState.EmailFlow };
      }
      return {
        state: RedemptionState.OtherFlow,
      };
    }
    return { state: RedemptionState.LoginRequired };
  }

  return { state: RedemptionState.EmailFormFlow };
}

function errorMessage(errorMessage: string) {
  return `There was an error while claiming the redemption. Please try again. Error: "${errorMessage}" If the
  problem persist please contact support.`;
}

export function checkIfEmailChanged(emailA?: string, emailB?: string) {
  return (
    emailA?.toLocaleLowerCase().trim() !== emailB?.toLocaleLowerCase().trim()
  );
}

// TODO: Whenever its needed, we will need to move this function to a more
// generic file and also to make it smarter with a dynamic key.
function replaceSellerName(str: string, replaceValue?: string): string {
  const value = str || "";
  if (!replaceValue) {
    return value;
  }
  return value.replace(SellerNameDynamicKey, replaceValue);
}

const blurredDealMock: Discount = {
  id: "",
  title: "",
  activationType: RedemptionType.Email,
  contactEmail: "",
  instructions:
    "Fusce vel nunc orci. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  restrictions:
    "Proin in imperdiet augue. Sed eros orci, eleifend a faucibus sit amet, porta ut lectus.",
  averageTotalSavingsPrefix: "exact",
  averageTotalSavingsAmount: 0,
  averageTotalSavingsNotes: null,
  averageTotalSavingsCurrency: "$",
  slug: "",
  description:
    "Quisque vulputate, ipsum quis gravida ullamcorper, urna tellus dictum nibh, eget viverra urna justo eu turpis. Sed porttitor, dui vel porta gravida, mi dui maximus est, nec tempus tellus ante at arcu. Nulla sagittis accumsan nibh non luctus. Cras at risus nisl. Morbi imperdiet leo id ullamcorper euismod. Praesent sit amet lobortis odio. Morbi sollicitudin consectetur tincidunt.",
  newCustomersOnly: false,
  listing: "",
  categories: [],
  redemption: [],
};

const Deal = ({
  item: initialItem,
  lastDealId,
  settings,
  loadingItem,
}: Props) => {
  const theme = useTheme();
  const { configuration } = useConfiguration();
  const { state } = useRedemtionFlow();
  const { user: realUser, loadingUser } = useUser();
  const { mocks } = useContext(EditorCtx);
  let user = realUser;
  if (mocks && "noUserMock" in mocks) {
    user = mocks?.noUserMock;
  }
  const [item, setItem] = useState(initialItem);
  const isBlurred =
    item?.blurredDeal && Object.keys(item.blurredDeal).length && !user?.id;
  const blurredId = item?.blurredDeal?.id;
  if (isBlurred) {
    item.deal = {
      ...blurredDealMock,
      ...item.blurredDeal,
      id: blurredDealMock.id,
    };
  }
  const dealId = String(item?.deal?.id);
  // TODO: Whenever the BE is ready, we must change this variable by the API request status
  const [mutating, setMutating] = useState(false);
  const [mutatingContactSupport, setMutatingContactSupport] = useState(false);
  const [contactSupportSuccess, setContactSupportSuccess] = useState(false);
  const [upgradedVisibility, setUpgradedVisibility] = useState(true);
  const [lastEmailVerificationId, setLastEmailVerificationId] = useState("");
  const navigateNewTab = useNavigateNewTabSync();
  const [stateMessage, setStateMessage] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<string>();
  const [warningMessage, setWarningMessage] = useState<ReactNode>(null);
  const guestUser = isGuestUser(user);
  const { isEditor, messages, setCurrentMessage } = useContext(EditorCtx);
  const { setPreviewParams } = useContext(PreviewBannerCtx);

  const deal = item?.deal;
  const redemption = deal?.redemption[0];
  const dealWasUpgraded =
    lastDealId &&
    deal?.id &&
    user?.id &&
    !guestUser &&
    lastDealId !== lastDealKey(item);
  const gatedButtonText =
    deal?.activationType === RedemptionType.Email
      ? settings.redemptionButtons?.emailRedemptions?.gated
      : settings.redemptionButtons?.urlRedemptions?.gated;
  const loading =
    loadingItem || loadingUser || (user?.id && deal?.id === blurredDealMock.id);
  const guestVerificationId = Number(deal?.guestEmailVerification);

  useEffect(() => {
    setItem(initialItem);
  }, [initialItem]);

  useEffect(() => {
    setLastEmailVerificationId(
      guestVerificationId ? String(guestVerificationId) : "",
    );
  }, [guestVerificationId]);

  useEffect(() => {
    if (!user?.guestUser) {
      if ((user && !user?.confirmed) || user?.guestUser) {
        setStateMessage(
          "Pending Email verification. Please go to your email and verify your account",
        );
      }
      if (
        (user?.confirmed && !user?.organization?.allowedInMarketplace) ||
        errorCode === ErrorCode.communityRequestPending
      ) {
        setStateMessage(
          `You're pending approval. Expect an email when you are approved by ${configuration?.managerOrganization.name}`,
        );
      }
    }
  }, [user, errorCode]);

  const handleRedemption = (
    createdRedemption: Redemption,
    tab?: BrowserTab,
  ) => {
    if (!createdRedemption.step) {
      setErrorCode(ErrorCode.communityRequestPending);
      return;
    }
    if (createdRedemption.landingPageUrl) {
      tab?.setLocation(createdRedemption.landingPageUrl);
    }
    if (item?.deal) {
      setItem({
        ...item,
        deal: {
          ...item.deal,
          redemption: [createdRedemption],
        },
      });
    }
  };

  const handleRedemptionError = (e: unknown) => {
    if (e instanceof ServerError) {
      if (e instanceof JSONAPIServerError) {
        setStateMessage(e.getMessage());
      } else {
        setWarningMessage(
          <StyledAlert severity="error">
            {errorMessage(e.getMessage())}
          </StyledAlert>,
        );
      }
      setErrorCode(String(e.getCode()));
    } else {
      throw e;
    }
  };

  const submitRedemption = async () => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    const tab = new BrowserTab();

    if (
      deal?.activationType === RedemptionType.Link &&
      state === RedemptionState.OtherFlow
    ) {
      tab.open();
    }
    setMutating(true);
    try {
      const { data } = await postRedemption(dealId).json();
      if (data) {
        handleRedemption(data as Redemption, tab); // When we resolve return types + errors in all endpoint, this included, we can remove this casted variable
      }
    } catch (e) {
      handleRedemptionError(e);
    }
    setMutating(false);
  };

  const onClaimNowClick = async () => {
    if (user?.partnerOwned) {
      setPreviewParams({
        type: "deal",
        onPreview: () => {
          // TODO: this need to be discussed, to define how this preview will work in all its steps
        },
        onRequest: () => {
          submitRedemption();
        },
      });
    } else {
      await submitRedemption();
    }
  };

  const resendEmail = async () => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    setMutating(true);
    try {
      await postResendEmail(dealId).json();
      setContactSupportSuccess(false);
    } catch (e) {
      handleRedemptionError(e);
    }
    setMutating(false);
  };

  const sendContactSupport = async () => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    setMutatingContactSupport(true);
    try {
      await postContactSupport(dealId).json();
      setContactSupportSuccess(true);
    } catch (e) {
      setContactSupportSuccess(false);
      handleRedemptionError(e);
    }
    setMutatingContactSupport(false);
  };

  const submitGuestForm = async (guestRedemption: GuestFlowRedemption) => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    setMutating(true);
    try {
      const hadEmailChanged = checkIfEmailChanged(
        user?.email,
        guestRedemption.email,
      );
      if (user?.confirmed && !hadEmailChanged) {
        const { data } = await postRedemption(dealId, guestRedemption).json();
        handleRedemption(data);
      } else {
        const { firstName, lastName } = splitFullName(guestRedemption.fullName);
        const { fullName, termsAndConditions, ...guestData } = guestRedemption;

        if (lastEmailVerificationId && !hadEmailChanged) {
          await resendGuestUserVerification({
            verificationId: lastEmailVerificationId,
          }).json();
        } else {
          const { data: createdGuest } = await createGuest({
            discountId: Number(dealId || blurredId),
            firstName,
            lastName,
            ...guestData,
          }).json();
          const verificationId = createdGuest?.guestEmailVerifications?.at(-1);
          if (verificationId) {
            setLastEmailVerificationId(verificationId);
          }
        }
      }
    } catch (e) {
      handleRedemptionError(e);
    }
    setMutating(false);
  };

  const contactSupport = contactSupportSuccess ? (
    <InlineAlert
      icon={<Icon fontSize="inherit">check</Icon>}
      severity="success"
    >
      {settings.redemptionButtons?.supportMessage}
    </InlineAlert>
  ) : (
    <Slide
      direction="left"
      in
      mountOnEnter
      unmountOnExit
      timeout={animationTimeout}
    >
      <StyledButton
        startIcon={mutatingContactSupport ? <ButtonProgress /> : null}
        disabled={mutatingContactSupport}
        onClick={sendContactSupport}
        customVariant="secondary"
      >
        Contact Support
      </StyledButton>
    </Slide>
  );

  const errorInConfiguration = <>Error Loading Configuration</>;
  const loadingActionSkeleton = (
    <CenterContainer>
      <SkeletonLoader variant="rectangular" height="3rem" />
    </CenterContainer>
  );

  const afterResendEmail = (
    <Slide
      direction="left"
      in
      mountOnEnter
      unmountOnExit
      timeout={animationTimeout}
    >
      <StyledButton
        onClick={resendEmail}
        startIcon={mutating ? <ButtonProgress /> : null}
        disabled={mutating}
      >
        {replaceSellerName(
          settings.redemptionButtons?.emailRedemptions?.postRedemption,
          item?.seller.name,
        )}
      </StyledButton>
    </Slide>
  );

  const postSentToInbox = !contactSupportSuccess ? (
    <Slide
      direction="left"
      in
      mountOnEnter
      unmountOnExit
      timeout={animationTimeout}
    >
      <InlineAlert
        icon={<Icon fontSize="inherit">check</Icon>}
        severity="success"
      >
        {settings.redemptionButtons?.emailRedemptions?.successMessage}
      </InlineAlert>
    </Slide>
  ) : (
    <></>
  );

  const postRedemptionFlow = (
    <ActionsWrapper alignment={settings.alignment}>
      <FlexTransitionGroup
        redemptionActionsLayout={settings.redemptionActionsLayout}
      >
        {deal?.activationType === RedemptionType.Email ? (
          afterResendEmail
        ) : (
          <Slide
            direction="left"
            in
            mountOnEnter
            unmountOnExit
            timeout={animationTimeout}
          >
            <StyledButton
              onClick={() => {
                if (redemption?.landingPageUrl) {
                  navigateNewTab(redemption?.landingPageUrl);
                }
              }}
              startIcon={mutating ? <ButtonProgress /> : null}
              disabled={mutating}
            >
              {replaceSellerName(
                settings.redemptionButtons?.urlRedemptions?.postRedemption,
                item?.seller.name,
              )}
            </StyledButton>
          </Slide>
        )}
        {!settings.redemptionButtons?.hideSupportButton && contactSupport}
        {deal?.activationType === RedemptionType.Email &&
          !mutating &&
          postSentToInbox}
      </FlexTransitionGroup>
    </ActionsWrapper>
  );

  const postEmailRedemptionFlow = loading ? (
    <SkeletonLoader />
  ) : (
    <>
      {!guestUser ? (
        <StyledCustomContainer>
          <ActionsWrapper alignment={settings.alignment}>
            <ActionsContainer
              redemptionActionsLayout={settings.redemptionActionsLayout}
              variant={settings.variant}
            >
              {afterResendEmail}
              {!settings.redemptionButtons?.hideSupportButton && contactSupport}
              {!mutating && postSentToInbox}
            </ActionsContainer>
          </ActionsWrapper>
        </StyledCustomContainer>
      ) : (
        <></>
      )}
    </>
  );

  const emailFlow = (
    <ActionsWrapper alignment={settings.alignment}>
      <ActionsContainer
        redemptionActionsLayout={settings.redemptionActionsLayout}
        variant={settings.variant}
      >
        {redemption ? (
          postEmailRedemptionFlow
        ) : (
          <StyledButton
            onClick={onClaimNowClick}
            startIcon={mutating ? <ButtonProgress /> : null}
            disabled={mutating}
          >
            {settings.redemptionButtons?.emailRedemptions?.claimNow}
          </StyledButton>
        )}
        {warningMessage && contactSupport}
      </ActionsContainer>
    </ActionsWrapper>
  );

  const otherFlow = (
    <StyledCustomContainer alignment={settings.alignment}>
      {redemption ? (
        postRedemptionFlow
      ) : (
        <StyledButton
          startIcon={mutating ? <ButtonProgress /> : null}
          onClick={onClaimNowClick}
          disabled={mutating}
        >
          {deal?.activationType === RedemptionType.Email
            ? `${replaceSellerName(
                settings.redemptionButtons?.emailRedemptions?.redemption,
                item?.seller.name,
              )}`
            : `${replaceSellerName(
                settings.redemptionButtons?.urlRedemptions?.redemption,
                item?.seller.name,
              )}`}
        </StyledButton>
      )}
    </StyledCustomContainer>
  );

  const loginRequired = (
    <StyledCustomContainer alignment={settings.alignment}>
      <LoginAnchorButton>{gatedButtonText}</LoginAnchorButton>
    </StyledCustomContainer>
  );

  const emailFormFlow = (
    <>
      {lastEmailVerificationId && !mutating && (
        <TextAlert
          color="primary"
          alignment={settings.alignment}
          icon={<Icon>email</Icon>}
        >
          Check your email! We&apos;ve sent a verification link to {user?.email}{" "}
          so you can claim this deal.
        </TextAlert>
      )}
      <FormContainer>
        <DealForm
          item={item}
          alignment={settings.alignment}
          redemptionButtons={settings.redemptionButtons}
          loading={mutating}
          guestUserCreated={!!lastEmailVerificationId}
          onSubmit={submitGuestForm}
          statusSection={
            redemption?.id ? <>{postEmailRedemptionFlow}</> : <></>
          }
        />
      </FormContainer>
    </>
  );

  const states = {
    [RedemptionState.ErrorInConfiguration]: errorInConfiguration,
    [RedemptionState.Loading]: loadingActionSkeleton,
    [RedemptionState.EmailFlow]: emailFlow,
    [RedemptionState.OtherFlow]: otherFlow,
    [RedemptionState.LoginRequired]: loginRequired,
    [RedemptionState.EmailFormFlow]: emailFormFlow,
  };

  const actionSection = states[state]; // TODO: check when we build skeleton loaders for all page, this may not be needed

  let actionSectionResult: ReactNode = actionSection;

  if (stateMessage) {
    // this code will not be like this, we need error status instead of checking the error message, BE is working on it
    if (
      errorCode === ErrorCode.communityRequestPending ||
      errorCode === ErrorCode.communityRequestRejected
    ) {
      actionSectionResult = (
        <TextAlert
          color="primary"
          alignment={settings.alignment}
          icon={
            errorCode === ErrorCode.communityRequestPending ? (
              <Icon>av_timer</Icon>
            ) : (
              <Icon>priority_high</Icon>
            )
          }
        >
          {stateMessage}
        </TextAlert>
      );
    } else {
      actionSectionResult = (
        <TextAlert
          color="warning"
          alignment={settings.alignment}
          icon={<Icon>warning</Icon>}
        >
          {stateMessage}
        </TextAlert>
      );
    }
  }

  if (!deal) {
    return <></>;
  }

  const content = (
    <Content>
      {/* TODO: Replace br for css */}
      <DescriptionText>{deal.description}</DescriptionText>
      <Typography>
        {deal.instructions && (
          <>
            <StyledBox fontWeight={700} display="inline">
              Redemption instructions:
            </StyledBox>{" "}
            {deal.instructions}
          </>
        )}
      </Typography>
      <Typography>
        {deal.restrictions && (
          <>
            <StyledBox fontWeight={700} display="inline">
              Special Restrictions:
            </StyledBox>{" "}
            {deal?.restrictions}
          </>
        )}
      </Typography>
      <StyledFlexContainer>
        {deal.newCustomersOnly ? (
          <>
            <StatusIconContainer>
              <CustomColorIcon
                customColor={settings.newCustomersOnlyColor}
                defaultColor="warning"
              >
                warning
              </CustomColorIcon>
            </StatusIconContainer>
            <WarningMessage color={settings.newCustomersOnlyColor}>
              Available for new customers only
            </WarningMessage>
          </>
        ) : (
          <>
            <StatusIconContainer>
              <CustomColorIcon
                customColor={settings.newAndExistingCustomersColor}
                defaultColor="success"
              >
                check_circle
              </CustomColorIcon>
            </StatusIconContainer>
            <SuccessMessage color={settings.newAndExistingCustomersColor}>
              Available for new and existing customers
            </SuccessMessage>
          </>
        )}
      </StyledFlexContainer>
    </Content>
  );

  return (
    <Main variant={settings?.variant}>
      <InnerContainer>
        {loading ? (
          <LoadingContainer>
            <SkeletonLoader variant="text" width="30%" />
            <SkeletonLoader variant="text" width="30%" />
            <div>
              <SkeletonLoader variant="text" />
              <SkeletonLoader variant="text" />
            </div>
            <div>
              <SkeletonLoader variant="text" />
              <SkeletonLoader variant="text" />
            </div>
            <SkeletonLoader variant="text" />
            <StyledSkeletonRectangular variant="rectangular" />
          </LoadingContainer>
        ) : (
          <>
            {warningMessage}
            <NameContainer>
              <StyledName size={settings.headerSize}>{deal.title}</StyledName>
              {dealWasUpgraded && (
                <Fade
                  in={upgradedVisibility}
                  addEndListener={() => {
                    setTimeout(() => {
                      setUpgradedVisibility(false);
                    }, 1000);
                  }}
                  timeout={{ exit: theme.transitions.duration.complex }}
                >
                  <UpgradedMessage> Upgraded!</UpgradedMessage>
                </Fade>
              )}
            </NameContainer>
            {!!deal.averageTotalSavingsAmount && (
              <StyledAverageSavings variant="h5">
                <EarningsBox
                  color={settings.savingAmountColor}
                  averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
                  averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
                  averageTotalSavingsCurrency={deal.averageTotalSavingsCurrency}
                />
              </StyledAverageSavings>
            )}
            {isBlurred ? <BlurredBox>{content}</BlurredBox> : content}
            <StyledDivider showSeparatorLane={settings.showSeparatorLine} />
            {dealWasUpgraded && (
              <StyledRedemptionStatus alignment={settings.alignment}>
                <Icon color="primary">star</Icon>
                <NotificationMessage>
                  Congratulations, you qualified for a better deal!
                </NotificationMessage>
              </StyledRedemptionStatus>
            )}
          </>
        )}
        {actionSectionResult}
      </InnerContainer>
    </Main>
  );
};

export type DealSettings = Settings;
export default Deal;
