export const filterBuilder = (key: string, options: { value: string }) =>
  `&filter[${key}]=${options.value}`;

export const sortingBuilder = (key: string, options: { asc: boolean }) => {
  const { asc } = options;
  const value = asc ? `${key}` : `-${key}`;
  return `&sort=${value}`;
};

export const includesBuilder = (options: { values: string[] }) =>
  `includes=${options.values.join(".")}`;
