import { Typography, styled } from "@mui/material";
import { dealLayoutSideContentWidth } from "../../public/DealDetailsLayout";
import { Settings as MoreInformationSettings } from "../../public/MoreInformation";
import MoreInformationFeature from "../MoreInformationFeature";
import Panel from "../Panel";

export type MoreInformationItemVariant = "text" | "link" | "chip" | "social";

interface MoreInformationItem {
  label: string;
  url?: string;
}

interface Props {
  items?: MoreInformationItem[];
  variant: MoreInformationItemVariant;
  title: string;
  config?: MoreInformationSettings;
}

export const Main = styled("div")<{ config?: MoreInformationSettings }>(
  ({ config, theme }) => ({
    marginRight: "2rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width:
        config?.placement === "vertical" ? dealLayoutSideContentWidth : "auto",
      marginRight: "0",
      marginBottom: config?.placement === "vertical" ? "2rem" : "0",
    },
  }),
);

export const ItemsContainer = styled("div")<{
  variant: MoreInformationItemVariant;
  settings?: MoreInformationSettings;
}>(({ variant, settings, theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  flex: 1,
  padding: 1,
  flexDirection: variant !== "chip" ? "column" : "row",
  [theme.breakpoints.up("md")]: {
    padding: 0,
    flexDirection:
      variant !== "chip" ||
      (variant === "chip" && settings?.placement === "horizontal")
        ? "column"
        : "row",
  },
}));

export const MoreInformationSectionTitle = styled(Typography)<{
  settings?: MoreInformationSettings;
}>(({ theme, settings }) => ({
  color: theme.palette.text.default,
  fontSize:
    settings?.placement === "horizontal"
      ? theme.typography.h5.fontSize
      : theme.typography.body1.fontSize,
  marginBottom: settings?.placement === "horizontal" ? "1rem" : "0.5rem",
  fontWeight: "bold",
}));

const MoreInformationSection = ({ items, variant, title, config }: Props) =>
  items?.length ? (
    <Main config={config}>
      <MoreInformationSectionTitle variant="h3" settings={config}>
        {title}
      </MoreInformationSectionTitle>
      <Panel variant={config?.variant}>
        <ItemsContainer settings={config} variant={variant}>
          {items?.map((item, idx) => (
            <MoreInformationFeature
              key={idx}
              url={item.url}
              label={item.label}
              variant={variant}
            />
          ))}
        </ItemsContainer>
      </Panel>
    </Main>
  ) : (
    <></>
  );

export default MoreInformationSection;
