import { FunctionComponent, useState } from "react";
import { styled } from "@mui/material";
import Script from "next/script";
import {
  REVIEWS_ALLOWED_FEATURE_KEY,
  useConfiguration,
} from "../../dataAccess/api/configuration";
import { Item } from "../../dataAccess/api/item";
import { useUser } from "../../dataAccess/api/user";
import { JSONAPIServerError } from "../../dataAccess/JSONAPIServerError";
import {
  Review,
  ReviewPayload,
  createReviewBySlug,
  postVerifyCaptcha,
  useReviews,
} from "../../dataAccess/api/review";
import ReviewsList from "../ui/Reviews/ReviewsList";
import { ThemeButton } from "../ui/ThemeComponents/ThemeButton";
import ReviewForm from "../ui/ReviewForm";
import SectionTitle from "../ui/SectionTitle";
import { DropdownItem } from "../ui/DropdownList";
import { PanelVariant } from "../ui/Panel";
import { PublicComponentProps } from "./PublicComponentProps";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";

export interface Settings extends CollapsableSectionSettings {
  reviewsShown: number;
  showSortOptions?: boolean;
  showFilterOptions?: boolean;
  showRating?: boolean;
  showReviewerName?: boolean;
  variant?: PanelVariant;
}

interface Props extends PublicComponentProps<Settings> {
  reviews?: Review[];
  item?: Item;
}

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  marginBottom: "1rem",
  gap: "0.5rem",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "0",
  },
}));

const Reviews: FunctionComponent<Props> = ({ item, settings, reviews }) => {
  const [showForm, setShowForm] = useState(false);
  const { configuration } = useConfiguration();
  const { user } = useUser();
  const [invalidCaptcha, setInvalidCaptcha] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<DropdownItem>({
      value: "",
      label: "",
    });
  const [selectedSortOption, setSelectedSortOption] = useState<DropdownItem>({
    value: "",
    label: "",
  });

  const { reviews: filteredReviews } = useReviews(
    item?.slug,
    selectedFilterOption.value,
    selectedSortOption.value,
  );

  const onSubmit = async (payload: ReviewPayload) => {
    try {
      if (!item) return false;
      if (user) {
        await createReviewBySlug(item.slug, payload).json();
        return true;
      }
      const token = window.grecaptcha.getResponse();
      if (!token && !user) {
        setInvalidCaptcha(true);
        return false;
      }
      setInvalidCaptcha(false);
      await postVerifyCaptcha({ token, slug: item.slug, ...payload }).json();
      return true;
    } catch (error) {
      const JSONAPIError = error as JSONAPIServerError;
      if (JSONAPIError.getMessage()) {
        setErrorMessage(JSONAPIError.getMessage());
      }
      return false;
    }
  };

  if (
    !item ||
    !configuration?.allowedFeatures.includes(REVIEWS_ALLOWED_FEATURE_KEY)
  )
    return <></>;

  const reviewsToRender = filteredReviews || reviews;
  return (
    <div>
      <CollapsableSection
        isCollapsable={settings.collapseSection}
        id="customers-reviews-accordion"
        title="Customers Reviews"
      >
        <Script
          src="https://www.google.com/recaptcha/api.js"
          strategy="afterInteractive"
        />
        {!showForm ? (
          <>
            <HeaderContainer>
              {!settings.collapseSection && (
                <SectionTitle spacing="none">Customers Reviews</SectionTitle>
              )}
              <ThemeButton onClick={() => setShowForm(true)}>
                Write a Review
              </ThemeButton>
            </HeaderContainer>
            {reviewsToRender && (
              <ReviewsList
                reviewsShown={settings.reviewsShown}
                variant={settings.variant}
                showRating={settings.showRating}
                selectedSortOption={selectedSortOption}
                setSelectedSortOption={setSelectedSortOption}
                setSelectedFilterOption={setSelectedFilterOption}
                selectedFilterOption={selectedFilterOption}
                showReviewerName={settings.showReviewerName}
                showFilterOptions={settings.showFilterOptions}
                showSortOptions={settings.showSortOptions}
                reviews={reviewsToRender}
              />
            )}
          </>
        ) : (
          <ReviewForm
            isInvalidCaptcha={invalidCaptcha}
            onCancel={() => setShowForm(false)}
            onSubmit={onSubmit}
            errorMessage={errorMessage}
          />
        )}
      </CollapsableSection>
    </div>
  );
};
export default Reviews;
