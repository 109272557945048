import { FunctionComponent } from "react";
import MoreInformationSection from "../ui/MoreInformationSection";
import { ChildrenProps } from "./MoreInformation";

export function displayCategoryList(item: Props["item"]) {
  return !!item?.categories?.length;
}

export interface Settings {
  title?: string;
}

type Props = ChildrenProps<Settings>;

const CategoryList: FunctionComponent<Props> = ({
  parentComponent,
  item,
  settings,
}) =>
  item && displayCategoryList(item) ? (
    <MoreInformationSection
      variant="chip"
      items={item.categories.map(({ name }) => ({ label: `#${name}` }))}
      title={settings?.title || "Categories"}
      config={parentComponent?.settings}
    />
  ) : (
    <></>
  );

export default CategoryList;
export type MoreInformationListSettings = Settings;
