import { FunctionComponent, useMemo } from "react";
import { Link, styled, Typography } from "@mui/material";
import { ChildrenProps as Props } from "./MoreInformation";
import {
  ItemsContainer,
  Main,
  MoreInformationSectionTitle,
} from "../ui/MoreInformationSection";
import { useNavigateNewTabSync } from "../../helpers/navigation";
import { ThemeButton } from "../ui/ThemeComponents/ThemeButton";
import Panel from "../ui/Panel";

const StyledLink = styled(Link)(({ theme }) => ({
  marginBottom: "0.5rem",
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

const StyledText = styled(Typography)(() => ({
  fontWeight: "700",
}));

const ClaimContent = styled("div")(() => ({
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
}));

const Claim: FunctionComponent<Props> = ({ parentComponent, item }) => {
  const navigateNewTab = useNavigateNewTabSync();

  const getClaimText = useMemo(() => {
    const baseText = "Do you work at";
    const orgName = item?.seller.name || "";
    return parentComponent?.settings.claimVariant === "text"
      ? `${baseText} ${orgName}? Claim`
      : `${baseText.toUpperCase()} ${orgName.toUpperCase()}?`;
  }, [parentComponent, item]);

  return process.env.NEXT_PUBLIC_MANAGER_APP_URL && item ? (
    <Main config={parentComponent?.settings}>
      <MoreInformationSectionTitle
        variant="h3"
        settings={parentComponent?.settings}
      >
        Claim
      </MoreInformationSectionTitle>
      <Panel variant={parentComponent?.settings?.variant}>
        {parentComponent?.settings.claimVariant === "text" ? (
          <ItemsContainer settings={parentComponent?.settings} variant="link">
            <StyledLink
              tabIndex={0}
              onClick={() => {
                if (process.env.NEXT_PUBLIC_MANAGER_APP_URL) {
                  navigateNewTab(process.env.NEXT_PUBLIC_MANAGER_APP_URL);
                }
              }}
            >
              {getClaimText}
            </StyledLink>
          </ItemsContainer>
        ) : (
          <ItemsContainer settings={parentComponent?.settings} variant="link">
            <ClaimContent>
              <StyledText>{getClaimText}</StyledText>
              <ThemeButton
                variant="contained"
                target="_blank"
                href={process.env.NEXT_PUBLIC_MANAGER_APP_URL}
              >
                Claim Account
              </ThemeButton>
            </ClaimContent>
          </ItemsContainer>
        )}
      </Panel>
    </Main>
  ) : (
    <></>
  );
};

export default Claim;
