import { styled } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import { LayoutComponent } from "../../../builder/Layout";
import { Position } from "../../../types/theme";
import Carousel from "../../ui/Carousel";
import { PublicComponentProps } from "../PublicComponentProps";
import { Subcomponents as DealDetailsLayoutSubcomponents } from "../DealDetailsLayout";
import { Item } from "../../../dataAccess/api/item";
import { displayCollectionList } from "../CollectionList";
import { displayCategoryList } from "../CategoryList";
import { displaySocialLinks } from "../SocialLinks";
import { displaySupport } from "../Support";
import { displayLanguages } from "../Languages";
import { displayHelpLinks } from "../HelpLinks";
import { PanelVariant } from "../../ui/Panel";

export interface Settings {
  placement: Exclude<Position, "above">;
  variant: PanelVariant;
  claimVariant: "text" | "button";
}

export interface ChildrenProps<T extends object = Record<string, never>>
  extends PublicComponentProps<
    T,
    Record<string, unknown>,
    Record<string, unknown>,
    Settings
  > {
  item?: Item;
}

interface Subcomponents {
  CollectionList?: LayoutComponent<Settings>;
  CategoryList?: LayoutComponent<Settings>;
  SocialLinks?: LayoutComponent<Settings>;
  Support?: LayoutComponent<Settings>;
  Languages?: LayoutComponent<Settings>;
  HelpLinks?: LayoutComponent<Settings>;
  Claim?: LayoutComponent<Settings>;
}

interface Props
  extends PublicComponentProps<
    Settings,
    Subcomponents,
    DealDetailsLayoutSubcomponents
  > {
  item?: Item;
}

const Main = styled("div")<{
  placement: Position;
  subComponentsLength: number;
}>(({ placement, theme, subComponentsLength }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: placement === "vertical" ? "column" : "row",
    gridColumn: placement === "vertical" ? "1 / 1" : "100%",
    gridRow: placement === "vertical" ? `1 / ${subComponentsLength}` : "100%",
  },
}));

const Slide = styled("div")<{
  first: boolean;
  placement: Position;
}>(({ first, placement, theme }) => ({
  width: "100vw",
  maxWidth: "100%",
  height: "100%",
  marginLeft: first || placement === "vertical" ? 0 : "1rem",
  [theme.breakpoints.up("md")]: {
    width: "auto",
  },
}));

const MoreInformation: FunctionComponent<Props> = ({
  settings,
  subComponents,
  siblingsComponents,
  item,
}: Props) => {
  const subComponentsToDisplay: Record<string, boolean> = {
    CollectionList: displayCollectionList(item),
    CategoryList: displayCategoryList(item),
    SocialLinks: displaySocialLinks(item),
    Support: displaySupport(item),
    Languages: displayLanguages(item),
    HelpLinks: displayHelpLinks(item),
    Claim: true,
  };

  const subComponentsLength = useMemo(() => {
    const subComponentsToDisplayLength = Object.keys(
      subComponentsToDisplay,
    ).length;
    if (!siblingsComponents) {
      return subComponentsToDisplayLength;
    }
    const siblingsComponentsLength = Object.keys(siblingsComponents).length;
    return siblingsComponentsLength > subComponentsToDisplayLength
      ? siblingsComponentsLength
      : subComponentsToDisplayLength;
  }, [siblingsComponents]);

  const subComponentsValues =
    (subComponents &&
      Object.keys(subComponents)
        .map((key) => {
          const value = subComponents[key as keyof Subcomponents];
          if (subComponentsToDisplay[key]) {
            return value;
          }
        })
        .filter((value) => value)) ||
    [];

  const { length } = subComponentsValues;

  return (
    <Main
      subComponentsLength={subComponentsLength + 1}
      placement={settings.placement}
    >
      {length && subComponents ? (
        <Carousel
          verticalHeight={
            settings.placement === "vertical" ? "auto" : undefined
          }
          itemMaxWidthPercentage={
            settings.placement === "horizontal" ? 33.3333333333 : undefined
          }
          fullWidthSwiper
          disableOnDesktop
        >
          {subComponentsValues.map((value, idx) => (
            <Slide placement={settings.placement} key={idx} first={!idx}>
              {value?.component || <></>}
            </Slide>
          ))}
        </Carousel>
      ) : (
        <></>
      )}
    </Main>
  );
};

export default MoreInformation;
