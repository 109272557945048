import { FunctionComponent, CSSProperties, Fragment } from "react";
import { styled, Box } from "@mui/material";
import { useConfiguration } from "src/dataAccess/api/configuration";
import { postReferral } from "../../dataAccess/api/analytics";
import { Alignment } from "../../types/theme";
import SkeletonLoader from "../ui/SkeletonLoader";
import { PublicComponentProps } from "./PublicComponentProps";
import Banner, { BannerConfiguration } from "../ui/Banner";
import { Container } from "../ui/DefaultPageContainer";
import TileLogo from "../ui/Tile/TileLogo";
import {
  MediaSetting,
  getJustificationByAlignment,
} from "../../builder/themeOptionsBuilder";
import type { NavigationComponentProps } from "../pages/PDP";
import PageNavigation from "../ui/PageNavigation";
import TileBannerBody from "../ui/TileBannerBody";

export interface Settings extends BannerConfiguration {
  showPartnerDescription?: boolean;
  showRedirectPartnerWebsite?: boolean;
  showRecommendationStars?: boolean;
  showShareUrlButton?: boolean;
  textShadow?: boolean;
  showIntegrationButton?: boolean;
  installNowButton: {
    text: string;
    target: "_blank" | "_parent";
  };
  logo: {
    // TODO add support for thickness, opacity, borders and blur (for tiles and tileBanner)
    style: "partnerOnly" | "both";
    size: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  };
  variant: "classic" | "elegant";
}

type Props = PublicComponentProps<Settings> & NavigationComponentProps;

const encasedFactorGrow = 1.2;
const sizeMapper = {
  extraSmall: {
    size: 70,
    translateEncasedFactor: 36 * encasedFactorGrow,
  },
  small: {
    size: 85,
    translateEncasedFactor: 51 * encasedFactorGrow,
  },
  medium: {
    size: 115,
    translateEncasedFactor: 64 * encasedFactorGrow,
  },
  large: {
    size: 130,
    translateEncasedFactor: 75 * encasedFactorGrow,
  },
  extraLarge: {
    size: 150,
    translateEncasedFactor: 84 * encasedFactorGrow,
  },
};

export const RootContainer = styled(Banner)<
  Settings["layout"] & { backButtonIsPresent?: boolean }
>(({ theme, layoutAlignment, textColor, backButtonIsPresent }) => {
  const { [theme.breakpoints.up("md")]: md, ...medias } =
    theme.getMediaForSettings(() => ({
      paddingTop: MediaSetting.top,
    }));
  const colorSettings = textColor
    ? {
        "& .MuiTypography-root": {
          color: textColor,
        },
      }
    : {};

  const verticalPadding = backButtonIsPresent ? "1.5em" : "1rem";

  return {
    width: "100%",
    display: "flex",
    alignItems: getJustificationByAlignment(layoutAlignment),
    flexDirection: "column",
    paddingTop: verticalPadding,
    paddingBottom: verticalPadding,
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      marginBottom: "3rem",
      ...md,
      ...colorSettings,
    },
    ...medias,
  };
});

function getLogoSize(logoSize: keyof typeof sizeMapper) {
  return sizeMapper[logoSize] || sizeMapper.medium;
}

function getLogoWidth(
  logoSize: keyof typeof sizeMapper,
  secondaryLogo: boolean,
) {
  const { size, translateEncasedFactor } = getLogoSize(logoSize);
  return size + (secondaryLogo ? translateEncasedFactor : 0);
}

const TileBanneContainer = styled(Box)<{
  layoutAlignment?: Settings["layout"]["layoutAlignment"];
  secondaryLogo?: string;
  logoSize?: Settings["logo"]["size"];
  hasDescription?: boolean;
  variant: Settings["variant"];
}>(({
  theme,
  layoutAlignment,
  secondaryLogo,
  logoSize,
  variant,
  hasDescription,
}) => {
  let rulesBasedOnAlignment: CSSProperties = {};
  const specialSizeCases: (keyof typeof sizeMapper)[] = ["extraLarge", "large"];
  const padding = specialSizeCases.includes(logoSize || "medium") ? 20 : 16;
  const isClassicVariant = variant === "classic";
  const contentPadding = isClassicVariant
    ? `calc(${
        getLogoWidth(logoSize || "medium", !!secondaryLogo) + padding
      }px + ${theme.defaultSpacing.md.page.edges})`
    : "1rem";
  const { size } = getLogoSize(logoSize || "medium");

  switch (layoutAlignment) {
    case "center":
      rulesBasedOnAlignment = {
        justifyContent: "center",
        ...(isClassicVariant
          ? {
              paddingTop: size + padding,
            }
          : {}),
      };
      break;
    case "right":
      rulesBasedOnAlignment = {
        paddingRight: contentPadding,
        paddingLeft: theme.defaultSpacing.md.page.edges,
        flexDirection: "row-reverse",
      };
      break;
    default:
      rulesBasedOnAlignment = {
        paddingLeft: contentPadding,
        paddingRight: theme.defaultSpacing.md.page.edges,
      };
  }
  return {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: size + padding * 1.5,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      paddingLeft: theme.defaultSpacing.md.page.edges,
      paddingRight: theme.defaultSpacing.md.page.edges,
      minHeight: size,
      alignContent: hasDescription ? "baseline" : "center",
      ...rulesBasedOnAlignment,
    },
  };
});

const LogoContainer = styled(Box)<{
  layoutAlignment?: Alignment;
  secondaryLogo: string;
  size: Settings["logo"]["size"];
}>(({ theme, layoutAlignment, secondaryLogo, size }) => ({
  filter: secondaryLogo ? "drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2))" : "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  order: -6,
  width: getLogoWidth(size, !!secondaryLogo),
  height: getLogoSize(size).size,
  marginRight: "1rem",
  position: "absolute",
  top: "1rem",
  [theme.breakpoints.up("md")]: {
    top: 0,
    marginRight: 0,
    left:
      layoutAlignment === "left"
        ? theme.defaultSpacing.md.page.edges
        : "initial",
    right:
      layoutAlignment === "right"
        ? theme.defaultSpacing.md.page.edges
        : "initial",
  },
}));

const NavigationContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(2),
  },
}));

// TODO: use and adapt EncasedImage component here
const NewEncased = styled(Box)<{
  primaryLogo?: string;
  secondaryLogo?: string;
  isSecondary?: boolean;
  size: Settings["logo"]["size"];
}>(({ isSecondary = false, theme, size }) => {
  const { size: logoSize } = getLogoSize(size);

  return {
    position: "absolute",
    left: !isSecondary ? "initial" : 0,
    right: isSecondary ? "initial" : 0,
    zIndex: isSecondary ? 1 : 2,
    [theme.breakpoints.up("md")]: {
      width: logoSize,
      height: logoSize,
    },
  };
});

const TileBanner: FunctionComponent<Props> = ({
  settings: {
    layout,
    logo: { size: logoSize = "medium", style = "partnerOnly" },
    textShadow,
    variant = "classic",
    showShareUrlButton = true,
    showIntegrationButton = true,
    showPartnerDescription = true,
    showRedirectPartnerWebsite = true,
    showRecommendationStars = true,
    installNowButton,
  },
  item,
  backButtonIn,
  isBackButtonIn,
  navigation,
}) => {
  const { configuration } = useConfiguration();
  if (!item) {
    return (
      <SkeletonLoader
        variant="rectangular"
        height={layout.minHeight || "20rem"}
        width="100%"
      />
    );
  }

  const partnerLogoUrl =
    configuration?.managerOrganization?.symbolLogoUrl ||
    configuration?.managerOrganization?.logoUrl ||
    "";

  const Wrapper = layout.contained ? Container : Fragment;
  const secondaryLogo =
    style === "both" && partnerLogoUrl ? partnerLogoUrl : "";
  const showNavigation = !!(isBackButtonIn && backButtonIn === "TileBanner");
  const { size } = getLogoSize(logoSize);

  const navigationContent = showNavigation && (
    <NavigationContainer>
      <PageNavigation
        navigation={{
          type: navigation,
          links: [{ label: item?.name || "" }],
        }}
        textColor={layout.textColor}
      />
    </NavigationContainer>
  );

  const sellerLogo = item?.seller?.symbolLogoUrl || item?.seller?.logoUrl;

  return (
    <Wrapper>
      <RootContainer layout={layout} backButtonIsPresent={showNavigation}>
        {variant === "classic" ? (
          <>
            <Container>
              {navigationContent}
              <TileBanneContainer
                logoSize={logoSize}
                secondaryLogo={secondaryLogo}
                layoutAlignment={layout.layoutAlignment}
                hasDescription={
                  !!(item?.seller?.description && showPartnerDescription)
                }
                variant={variant}
              >
                <LogoContainer
                  secondaryLogo={secondaryLogo}
                  layoutAlignment={layout.layoutAlignment}
                  size={logoSize}
                >
                  <NewEncased size={logoSize} secondaryLogo={secondaryLogo}>
                    {sellerLogo && (
                      <TileLogo
                        logoUrl={sellerLogo}
                        orgName={item?.seller?.name}
                        width={size}
                        height={size}
                      />
                    )}
                  </NewEncased>
                  {sellerLogo && (
                    <NewEncased
                      size={logoSize}
                      secondaryLogo={secondaryLogo}
                      isSecondary
                    >
                      <TileLogo
                        logoUrl={secondaryLogo}
                        orgName={item.name}
                        width={size}
                        height={size}
                      />
                    </NewEncased>
                  )}
                </LogoContainer>
                <TileBannerBody
                  item={item}
                  layoutAlignment={layout?.layoutAlignment}
                  showIntegrationButton={showIntegrationButton}
                  showPartnerDescription={showPartnerDescription}
                  showRecommendationStars={showRecommendationStars}
                  showRedirectPartnerWebsite={showRedirectPartnerWebsite}
                  showShareUrlButton={showShareUrlButton}
                  textShadow={textShadow}
                  postReferral={() =>
                    postReferral(item.slug, item.deal?.id).json()
                  }
                />
              </TileBanneContainer>
            </Container>
          </>
        ) : (
          <Container>
            {navigationContent}
            <TileBanneContainer
              variant={variant}
              layoutAlignment={layout.layoutAlignment}
            >
              <TileBannerBody
                item={item}
                layoutAlignment={layout.layoutAlignment}
                showIntegrationButton={showIntegrationButton}
                showPartnerDescription={showPartnerDescription}
                showRecommendationStars={showRecommendationStars}
                showRedirectPartnerWebsite={showRedirectPartnerWebsite}
                showShareUrlButton={showShareUrlButton}
                textShadow={textShadow}
                postReferral={() =>
                  postReferral(item.slug, item.deal?.id).json()
                }
                installNowButton={installNowButton}
              />
            </TileBanneContainer>
          </Container>
        )}
      </RootContainer>
    </Wrapper>
  );
};

export default TileBanner;
