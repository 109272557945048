import { Button, styled } from "@mui/material";
import Image from "next/legacy/image";
import { ComponentProps, useMemo } from "react";
import Icon from "@mui/material/Icon";
import GalleryUI from "../ui/Gallery";
import PreviewDialogButton from "../ui/PreviewDialogButton";
import Video from "../ui/Video";
import { Item } from "../../dataAccess/api/item";
import SectionTitle from "../ui/SectionTitle";
import { PublicComponentProps } from "./PublicComponentProps";
import { Subcomponents as DealDetailsLayoutSubcomponents } from "./DealDetailsLayout";
import FullCarousel from "../ui/FullCarousel";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";
import type { EmbedVideo } from "../../dataAccess/thirdParty/embedly/Embedly";

type NextLegacyImageDimension = any; // Added due to wrong types in Next/Legacy/Image, from now on we should never use Legacy and we should use Next/Image instead.

const Slide = styled("div")(({ theme }) => ({
  background: theme.palette.panelsBackground.default,
  borderRadius: ".2rem",
  height: "30vw",
  maxHeight: "100%",
  position: "relative",
  cursor: "pointer",
  "& > span": {
    display: "flex",
    alignItems: "center",
    height: "100% !important",
  },
}));

const VideoThumb = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  background: "#000",
  top: 0,
  left: 0,
  opacity: 0.2,
  height: "100%",
}));

const PreviewButton = styled(Button)(() => ({
  display: "block",
  width: "100%",
  padding: "0 !important",
}));

const StyledIcon = styled(Icon)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  display: "flex",
  alignItems: "center",
}));

const thumbImageAttributes: Partial<ComponentProps<typeof Image>> = {
  width: "100%" as NextLegacyImageDimension,
  height: "100%" as NextLegacyImageDimension,
  layout: "responsive",
  objectFit: "contain",
};

interface Settings extends CollapsableSectionSettings {
  thumbs: boolean;
}

interface Props
  extends PublicComponentProps<
    Settings,
    Record<string, void>,
    DealDetailsLayoutSubcomponents
  > {
  item?: Item;
  embedVideo?: EmbedVideo;
}

const Gallery = ({ item, embedVideo, siblingsComponents, settings }: Props) => {
  const content = useMemo(() => {
    const fullCarouselContent =
      item?.mediaFiles.map(({ url, id, filename }) => (
        <Image
          key={id}
          alt={filename}
          src={url}
          {...thumbImageAttributes}
          height={"100%" as NextLegacyImageDimension}
        />
      )) || [];

    if (embedVideo) {
      fullCarouselContent.unshift(
        <Video key="item-video" embedHtml={embedVideo?.html || ""} />,
      );
    }

    const files =
      item?.mediaFiles.map(({ url, filename }, index) => {
        const thumbComponent = (
          <Slide>
            <Image alt={filename} src={url} {...thumbImageAttributes} />
          </Slide>
        );
        return {
          component: (
            <Slide>
              <PreviewDialogButton
                renderClickable={(open) => (
                  <PreviewButton onClick={() => open()}>
                    {thumbComponent}
                  </PreviewButton>
                )}
              >
                <FullCarousel initialSlide={embedVideo ? index + 1 : index}>
                  {fullCarouselContent}
                </FullCarousel>
              </PreviewDialogButton>
            </Slide>
          ),
          thumbComponent,
        };
      }) || [];

    if (embedVideo) {
      const videoThumbComponent = (
        <Slide>
          <Image
            alt={embedVideo?.title}
            src={embedVideo?.thumbnail_url || ""}
            {...thumbImageAttributes}
            unoptimized
          />
          <>
            <VideoThumb />
            <StyledIcon fontSize="medium">play_circle</StyledIcon>
          </>
        </Slide>
      );
      files.unshift({
        component: (
          <Slide>
            <PreviewDialogButton
              renderClickable={(open) => (
                <PreviewButton onClick={() => open()}>
                  {videoThumbComponent}
                </PreviewButton>
              )}
            >
              <FullCarousel initialSlide={0}>
                {fullCarouselContent}
              </FullCarousel>
            </PreviewDialogButton>
          </Slide>
        ),
        thumbComponent: videoThumbComponent,
      });
    }
    return files;
  }, [item?.mediaFiles, embedVideo]);

  return (!!item?.mediaFiles?.length || embedVideo) && content ? (
    <div>
      <CollapsableSection
        isCollapsable={settings.collapseSection}
        id="gallery-accordion"
        title="Gallery"
      >
        {!settings.collapseSection && <SectionTitle>Gallery</SectionTitle>}
        <GalleryUI
          thumbs={settings?.thumbs}
          thumbsPosition={
            siblingsComponents?.MoreInformation?.settings.placement ===
            "vertical"
              ? "horizontal"
              : "vertical"
          }
          content={content}
        />
      </CollapsableSection>
    </div>
  ) : (
    <></>
  );
};

export default Gallery;
