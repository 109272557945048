import { FunctionComponent } from "react";
import MoreInformationSection from "../ui/MoreInformationSection";
import { ChildrenProps as Props } from "./MoreInformation";

export function displayLanguages(item: Props["item"]) {
  return !!item?.languages?.length;
}

const Languages: FunctionComponent<Props> = ({ parentComponent, item }) =>
  item && displayLanguages(item) ? (
    <MoreInformationSection
      variant="text"
      items={item.languages?.map((label) => ({ label })) || []}
      title="Languages"
      config={parentComponent?.settings}
    />
  ) : (
    <></>
  );

export default Languages;
