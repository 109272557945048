import { styled } from "@mui/material";
import { ReactNode, useState } from "react";
import Swiper from "swiper";
import { Position, ThemeSettings } from "../../types/theme";
import Carousel from "./Carousel";
import OnlyDesktop from "./OnlyDesktop";

export interface Media {
  component: ReactNode;
  thumbComponent: ReactNode;
}

interface Props {
  thumbs?: boolean;
  thumbsPosition?: Omit<Position, "above">;
  content: Media[];
}

const height = 24;
const desktopSlidesPerView = 3;

type ContainerProps = Pick<Props, "thumbsPosition">;

const Container = styled("div")<ContainerProps>(
  ({ thumbsPosition, theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: thumbsPosition === "vertical" ? "row" : "column",
    },
  }),
);

const padding = 1;
const thumbsContainerWidth = 12;
const ContentContainer = styled("div")<ContainerProps>(
  ({ theme, thumbsPosition }) => ({
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      width:
        thumbsPosition === "vertical"
          ? `calc(100% - ${thumbsContainerWidth}rem)`
          : "auto",
      paddingBottom: thumbsPosition !== "vertical" ? `${padding}rem` : 0,
      paddingRight: thumbsPosition === "vertical" ? `${padding}rem` : 0,
    },
  }),
);
const ThumbsContainer = styled("div")<ContainerProps>(({ thumbsPosition }) => ({
  width: thumbsPosition === "vertical" ? `${thumbsContainerWidth}rem` : "100%",
  maxWidth: `${(thumbsContainerWidth + padding) * desktopSlidesPerView}rem`,
}));

const ThumbContainer = styled("div")<ContainerProps>(({ thumbsPosition }) => ({
  height: `${height / desktopSlidesPerView - padding}rem`,
  width: thumbsPosition === "vertical" ? "100%" : "90%",
}));

const Gallery = ({ thumbs = false, thumbsPosition, content }: Props) => {
  thumbsPosition = thumbs ? thumbsPosition : "horizontal";
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);
  const overrideSettings: ThemeSettings["carousels"] | undefined = thumbs
    ? {
        variant: "arrows",
      }
    : undefined;
  return (
    <Container thumbsPosition={thumbsPosition}>
      <ContentContainer thumbsPosition={thumbsPosition}>
        <Carousel
          fullWidthSwiper
          navigationOptions={{ disabled: thumbs }}
          slidesPerViewOnDesktop={1}
          overrideSettings={overrideSettings}
          {...(thumbs
            ? {
                thumbs: {
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                },
              }
            : {})}
        >
          {content.map(({ component }) => component)}
        </Carousel>
      </ContentContainer>
      {thumbs && (
        <ThumbsContainer thumbsPosition={thumbsPosition}>
          <OnlyDesktop>
            <Carousel
              fullWidthSwiper
              navigationOptions={{ position: "around" }}
              overrideSettings={overrideSettings}
              slidesPerViewOnDesktop={desktopSlidesPerView}
              onSwiper={setThumbsSwiper}
              verticalHeight={
                thumbsPosition === "vertical" ? `${height}rem` : undefined
              }
              vertical={thumbsPosition === "vertical"}
            >
              {content.map(({ thumbComponent }, index) => (
                <ThumbContainer key={index} thumbsPosition={thumbsPosition}>
                  {thumbComponent}
                </ThumbContainer>
              ))}
            </Carousel>
          </OnlyDesktop>
        </ThumbsContainer>
      )}
    </Container>
  );
};

export default Gallery;
