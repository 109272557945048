import useSWR from "swr";
import { buildThirdPartyUrlWithQueryParams } from "../../QueryParams";
import { APIEmbedly } from "./HTTPClientEmbedly";

export interface EmbedVideo {
  provider_url?: string;
  description?: string;
  title?: string;
  url?: string;
  author_name?: string;
  height?: number;
  thumbnail_width?: number;
  width?: number;
  html?: string;
  author_url?: string;
  version?: string;
  provider_name?: string;
  thumbnail_url?: string;
  type?: string;
  thumbnail_height?: number;
}

export function getEmbedVideo(videoUrl: string) {
  const url = buildThirdPartyUrlWithQueryParams("", {
    key: process.env.NEXT_PUBLIC_EMBEDLY_API_KEY,
    url: encodeURI(videoUrl),
    autoplay: "true",
  });
  const request = () => APIEmbedly.get<EmbedVideo>(url);
  return {
    json: async () => {
      if (!videoUrl) {
        return;
      }
      const response = await request();
      const result = await response.json();
      return result;
    },
    fallbackKey: url,
  };
}

export function useEmbedVideo(videoUrl: string) {
  const { fallbackKey, json } = getEmbedVideo(videoUrl);
  const { data } = useSWR(videoUrl ? fallbackKey : null, json);
  return {
    embedVideo: data,
  };
}
