import { getRequestContext } from "../../pages/[[...page]]";
import { HTTPClient, HTTPResponse } from "./HTTPClient";

class HTTPClientInternal extends HTTPClient {
  protected host?: string;

  protected async request<T>(
    url: string,
    config: RequestInit,
  ): Promise<HTTPResponse<T>> {
    this.host = `${
      process.env.NEXT_PUBLIC_MOCK_URL_PROTOCOL || "https"
    }://${getRequestContext()?.host}`;
    return fetch(this.getRequestUrl(url), config);
  }
}

export const APIInternal = new HTTPClientInternal();
