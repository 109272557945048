import { styled, useTheme } from "@mui/material";
import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from "swiper/react";

import "swiper/css";
import { NavigationArrow } from "./Carousel";

interface Props {
  children: ReactNode[];
  initialSlide: SwiperProps["initialSlide"];
}

const StyledSwiper = styled(Swiper)(() => ({
  width: "100%",
  height: "100%",
  "& :first-child": {
    width: "100% !important",
    height: "100% !important",
  },
}));

const FullCarousel: FunctionComponent<Props> = ({
  children,
  initialSlide,
}: Props) => {
  const [swiper, setSwiper] = useState<ReturnType<typeof useSwiper> | null>(
    null,
  );
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const theme = useTheme();

  const NavigateBeforeIcon = theme.icons.navigateBefore;
  const NavigateNextIcon = theme.icons.navigateNext;

  const arrowStart = useMemo(
    () => (
      <NavigationArrow
        onClick={() => {
          swiper?.slidePrev();
        }}
        disabled={isBeginning || swiper?.isLocked}
        position="center"
        vertical
      >
        <NavigateBeforeIcon />
      </NavigationArrow>
    ),
    [swiper, isBeginning],
  );

  const arrowEnd = useMemo(
    () => (
      <NavigationArrow
        onClick={() => {
          swiper?.slideNext();
        }}
        disabled={isEnd || swiper?.isLocked}
        position="center"
        vertical
      >
        <NavigateNextIcon />
      </NavigationArrow>
    ),
    [swiper, isEnd],
  );

  const slides = useMemo(
    () =>
      children.map((slide, index) => (
        <SwiperSlide key={index}>
          {swiper?.activeIndex === index ? slide : <></>}
        </SwiperSlide>
      )),
    [children, swiper?.activeIndex],
  );

  return (
    <>
      {arrowStart}
      <StyledSwiper
        initialSlide={initialSlide}
        direction="vertical"
        slidesPerView="auto"
        onSlideChange={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        speed={0}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
      >
        {slides}
      </StyledSwiper>
      {arrowEnd}
    </>
  );
};

export default FullCarousel;
