import { styled, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../../ui/CollapsableSection";
import SectionTitle from "../../ui/SectionTitle";
import EncasedImage from "../../ui/EncasedImage";
import Carousel from "../../ui/Carousel";
import { Item } from "../../../dataAccess/api/item";
import { PublicComponentProps } from "../PublicComponentProps";
import { getNameInitials } from "../../../helpers/formatter";

export interface Settings extends CollapsableSectionSettings {
  showNames: boolean;
}

interface Props extends PublicComponentProps<Settings> {
  item?: Item;
}
const EncasedImageContainer = styled("div")(({ theme }) => ({
  width: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    marginBottom: "1rem",
  },
}));

const Main = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const StyledCustomerName = styled(Typography)(() => ({
  marginTop: ".325rem",
}));

const CustomersWhoUse: FunctionComponent<Props> = ({
  item,
  settings,
}: Props) =>
  item?.showcasedOrganizations && item?.showcasedOrganizations.length > 0 ? (
    <Main>
      <CollapsableSection
        isCollapsable={settings.collapseSection}
        id="customers-who-use-accordion"
        title={`Customers Who Use ${item.seller.name} today`}
      >
        {!settings.collapseSection && (
          <SectionTitle>
            Customers Who Use {item.seller.name} today
          </SectionTitle>
        )}
        <Carousel disableOnDesktop={false}>
          {item.showcasedOrganizations.map((customer, idx) => (
            <>
              <EncasedImageContainer key={idx}>
                <EncasedImage
                  alt={customer.showcasedCustomerName}
                  variant="roundedBox"
                  src={customer.showcasedCustomerLogoUrl}
                  width={60}
                  height={60}
                  initials={getNameInitials(customer.showcasedCustomerName)}
                />
                {settings.showNames && (
                  <StyledCustomerName>
                    {customer.showcasedCustomerName}
                  </StyledCustomerName>
                )}
              </EncasedImageContainer>
            </>
          ))}
        </Carousel>
      </CollapsableSection>
    </Main>
  ) : (
    <></>
  );

export default CustomersWhoUse;
