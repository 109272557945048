import { FunctionComponent } from "react";
import MoreInformationSection from "../ui/MoreInformationSection";
import { ChildrenProps as Props } from "./MoreInformation";

export function displaySupport(item: Props["item"]) {
  return !!(item?.supportEmail || item?.supportUrl || item?.supportPhoneNumber);
}

const Support: FunctionComponent<Props> = ({ parentComponent, item }) =>
  item && displaySupport(item) ? (
    <MoreInformationSection
      variant="link"
      items={[
        { label: item.supportEmail || "", url: `mailto:${item.supportEmail}` },
        {
          label: item.supportPhoneNumber || "",
          url: `tel:${item.supportPhoneNumber}`,
        },
        {
          label: item.supportUrl ? "Website" : "",
          url: item.supportUrl ?? undefined,
        },
      ]}
      title="Support"
      config={parentComponent?.settings}
    />
  ) : (
    <></>
  );

export default Support;
