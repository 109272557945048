import React from "react";
import { Box, Icon, Rating, styled, Typography } from "@mui/material";

interface Props {
  showVotesNumber?: boolean;
  totalVotes: number;
  value: number;
}

const RatingContainer = styled(Box)(() => ({
  display: "inline-flex",
  alignItems: "center",
  width: "unset",
}));

const CountLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  marginTop: "0.1rem",
  marginLeft: "0.3rem",
  color: theme.palette.primary.main,
}));

const EmptyStar = styled("div")(() => ({
  opacity: 0.5,
  position: "relative",
  lineHeight: 0,
}));

const Star = styled(Icon)(() => ({
  color: "#fff",
}));

const StarBorder = styled(Icon)(() => ({
  position: "absolute",
  left: 0,
}));

const RatingStars: React.FunctionComponent<Props> = ({
  showVotesNumber,
  totalVotes,
  value,
}) => (
  <RatingContainer>
    <Rating
      value={value}
      readOnly
      precision={0.5}
      emptyIcon={
        <EmptyStar>
          <Star>star</Star>
          <StarBorder>star_border</StarBorder>
        </EmptyStar>
      }
    />
    {showVotesNumber && <CountLabel>{totalVotes}</CountLabel>}
  </RatingContainer>
);

export default RatingStars;
