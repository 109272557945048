import { GetServerSidePropsContext } from "next";
import Head from "next/head";
import { useEffect, useMemo } from "react";
import {
  EmbedVideo,
  getEmbedVideo,
  useEmbedVideo,
} from "../../dataAccess/thirdParty/embedly/Embedly";
import { useComponentsToForceExclude } from "../../app/componentHooks";
import CommonHead from "../../builder/CommonHead";
import { localStorageWrapper } from "../../dataAccess/LocalStorage";
import {
  Configuration,
  REVIEWS_ALLOWED_FEATURE_KEY,
  useConfiguration,
} from "../../dataAccess/api/configuration";
import { getReviewsBySlug, useReviews } from "../../dataAccess/api/review";
import { useUser } from "../../dataAccess/api/user";
import { Layout } from "../../builder/Layout";
import {
  getRouteFromServerSideContext,
  useCurrentRoute,
} from "../../builder/mapping/page";
import { PickQueryParams, useQueryParams } from "../../dataAccess/QueryParams";
import {
  Item,
  getItem,
  getSimilarTo,
  useItem,
  useQuoteHistoryAvailable,
  useSimilarTo,
} from "../../dataAccess/api/item";
import Profile, { ProfileContext, useProfileState } from "../public/Profile";
import BannerWithLogo from "../public/BannerWithLogo";
import BannerWithTextCustomHTML from "../public/BannerWithTextCustomHTML";
import BannerWithTextWithImage from "../public/BannerWithTextWithImage";
import BannerManageProfileSignUp from "../public/BannerManageProfileSignUp";
import CTAButton from "../public/CTAButton";
import Header from "../public/Header";
import Text from "../public/Text";
import DealDetailsLayout from "../public/DealDetailsLayout";
import MoreInformation from "../public/MoreInformation";
import Features from "../public/Features";
import CustomersWhoUse from "../public/CustomersWhoUse";
import Gallery from "../public/Gallery";
import CategoryList from "../public/CategoryList";
import ManageProfileSignUpButton from "../public/ManageProfileSignUpButton";
import CollectionList from "../public/CollectionList";
import SocialLinks from "../public/SocialLinks";
import Support from "../public/Support";
import Languages from "../public/Languages";
import HelpLinks from "../public/HelpLinks";
import Claim from "../public/Claim";
import HowItWorks from "../public/HowItWorks";
import SimilarTo, { getSimilarToComponentFromPDP } from "../public/SimilarTo";
import Deal from "../public/Deal";
import Quote from "../public/Quote/Quote";
import TileBanner from "../public/TileBanner";
import Pricing from "../public/Pricing";
import { getCategories, useCategories } from "../../dataAccess/api/categories";
import NotFound from "../ui/NotFound";
import { MainContainer } from "./PLP";
import { PageSettings } from "../../builder/PageConfiguration";
import Reviews from "../public/Reviews";
import { NavigationType } from "../ui/PageNavigation";
import type { ComponentType } from "../../wysiwyg/JsonThemeObject";
import { useScreenshotPreviewElementEffect } from "../../helpers/environment";
import { useAnalyticsTracker } from "../../app/AnalyticsTracker";
import PoweredByBuiltfirstBanner from "../ui/PoweredByBuiltfirstBanner";
import { getQuoteComponentFromPDP } from "../public/Quote/getQuoteComponentFromPDP";

interface Settings extends PageSettings {
  isBackButtonIn?: boolean;
  navigation?: NavigationType;
}

interface Props {
  settings: Settings;
}

/**
 * this imports are not dynamic to reduce bundle number of files:
 */
export const availableComponents = {
  BannerWithLogo,
  BannerWithTextCustomHTML,
  BannerWithTextWithImage,
  BannerManageProfileSignUp,
  CTAButton,
  Header,
  Text,
  DealDetailsLayout,
  MoreInformation,
  SimilarTo,
  Features,
  CustomersWhoUse,
  Gallery,
  ManageProfileSignUpButton,
  CategoryList,
  CollectionList,
  SocialLinks,
  Support,
  Languages,
  HelpLinks,
  HowItWorks,
  Profile,
  Deal,
  Quote,
  TileBanner,
  Pricing,
  Claim,
  Reviews,
};

export type AvailableComponents = typeof availableComponents;

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
  configuration: Configuration,
) => {
  const route = getRouteFromServerSideContext(context);
  const itemId = String(route?.params.id);
  const { json: itemJson, fallbackKey: itemFallbackKey } = getItem(itemId, {
    includeQuoteForms: !!getQuoteComponentFromPDP(configuration),
  });
  const { json: similarToJson, fallbackKey: similarToFallbackKey } =
    getSimilarTo(
      itemId,
      getSimilarToComponentFromPDP(configuration)?.settings?.similarItemsBy,
    );

  const { json: reviewsJson, fallbackKey: reviewsFallbackKey } =
    getReviewsBySlug(itemId);

  const { json: categoriesJson, fallbackKey: categoriesFallbackKey } =
    getCategories();

  let embedVideoFallbackKey = "";
  const itemPromise = itemJson();
  const embedVideoPromise = itemPromise.then(async (item) => {
    let jsonPromise: Promise<EmbedVideo | undefined> | null = null;
    try {
      const { json: embedVideoJson, fallbackKey } = getEmbedVideo(
        item?.videoUrl || "",
      );
      embedVideoFallbackKey = fallbackKey;
      jsonPromise = embedVideoJson();
    } catch (e) {
      console.error(e);
    }
    return jsonPromise;
  });

  const [item, embedVideo, categoriesResult, similarToResult, reviews] =
    await Promise.all([
      itemPromise,
      embedVideoPromise,
      categoriesJson(),
      similarToJson(),
      ...(configuration.allowedFeatures.includes(REVIEWS_ALLOWED_FEATURE_KEY)
        ? [reviewsJson()]
        : []),
    ]);

  return {
    props: {
      fallback: {
        [itemFallbackKey]: item,
        [similarToFallbackKey]: similarToResult,
        [categoriesFallbackKey]: categoriesResult,
        ...(configuration.allowedFeatures.includes(
          REVIEWS_ALLOWED_FEATURE_KEY,
        ) && {
          [reviewsFallbackKey]: reviews,
        }),
        ...(embedVideoFallbackKey &&
          embedVideo && {
            [embedVideoFallbackKey]: embedVideo,
          }),
      },
    },
  };
};

let lastDealId: string | undefined;

export function lastDealKey(item?: Item) {
  const dealId =
    item?.deal?.id ||
    (item?.blurredDeal?.id && `${item?.blurredDeal?.id}|blurred`);
  return dealId ? `${item?.id}${dealId}` : undefined;
}

export const lastVisitedPDPKey = "last-visited-pdp";

export interface NavigationComponentProps {
  item?: Item;
  backButtonIn?: ComponentType;
  isBackButtonIn?: boolean;
  navigation?: NavigationType;
}

const PDP = ({ settings }: Props) => {
  const { preview_deal_id } = useQueryParams();
  const currentRoute = useCurrentRoute<PickQueryParams<"id">>();
  const { componentsToForceExclude } = useComponentsToForceExclude();
  const slug = currentRoute?.params.id;
  const { item, loadingItem } = useItem(slug, preview_deal_id);
  const { user } = useUser();
  const { quoteHistoryAvailable, loadingQuoteHistoryAvailable } =
    useQuoteHistoryAvailable({ doRequest: !!user?.confirmed });
  const profileState = useProfileState();
  const { configuration } = useConfiguration();
  useScreenshotPreviewElementEffect();
  const similarBy = useMemo(
    () => getSimilarToComponentFromPDP(configuration)?.settings?.similarItemsBy,
    [configuration?.publishedTemplate],
  );
  const { similarItems } = useSimilarTo(slug, similarBy);
  const { reviews } = useReviews(slug);
  const { embedVideo } = useEmbedVideo(item?.videoUrl || "");
  const { categoryList } = useCategories();
  const { AnalyticsTracker } = useAnalyticsTracker();

  const pageConfiguration =
    configuration?.publishedTemplate.properties.pages.find(
      ({ id }: { id: string }) => id === "PDP",
    );

  const navigationComponents: ComponentType[] = [
    "Profile",
    "TileBanner",
    "BannerWithLogo",
  ];

  useEffect(() => {
    AnalyticsTracker.trackIfItemClick({
      item,
      user,
    });
  }, [item, user]);

  const navigationIndexList = useMemo(
    () =>
      navigationComponents.map((componentName) => {
        const index = pageConfiguration?.components?.findIndex(
          ({ type }) => type === componentName,
        );
        return index !== undefined &&
          !componentsToForceExclude?.includes(componentName)
          ? index
          : -1;
      }),
    [pageConfiguration],
  );

  const minNavigationIndex = Math.min(
    ...navigationIndexList.filter((index) => index >= 0),
  );

  const backButtonIn: NavigationComponentProps["backButtonIn"] =
    navigationComponents[navigationIndexList.indexOf(minNavigationIndex)];

  useEffect(() => {
    const key = lastDealKey(item);
    if (key) {
      if (!user?.id) {
        lastDealId = key;
      }
      if (lastDealId && lastDealId !== key) {
        lastDealId = undefined;
      }
    }
    localStorageWrapper.setItem(lastVisitedPDPKey, item?.slug || "");
  }, [item, user?.id]);

  if (!loadingItem && !item?.id) {
    return <NotFound />;
  }

  return (
    <MainContainer id="app-content">
      <CommonHead titleSufix={item?.seller.name} />
      <Head>
        {item?.description && (
          <meta name="description" content={item?.description} />
        )}
      </Head>
      <ProfileContext.Provider value={profileState}>
        <Layout
          categories={categoryList?.data}
          similarItems={similarItems}
          reviews={reviews}
          item={item}
          loadingItem={loadingItem}
          embedVideo={embedVideo}
          lastDealId={lastDealId}
          availableComponents={availableComponents}
          isBackButtonIn={settings?.isBackButtonIn}
          navigation={settings?.navigation}
          homePageLogo={configuration?.homePageLogoUrl}
          marketplaceName={configuration?.name}
          backButtonIn={backButtonIn}
          quoteHistoryAvailable={quoteHistoryAvailable}
          loadingQuoteHistoryAvailable={loadingQuoteHistoryAvailable}
        />
        <PoweredByBuiltfirstBanner />
      </ProfileContext.Provider>
    </MainContainer>
  );
};

export default PDP;
