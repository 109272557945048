import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Item } from "../../dataAccess/api/item";
import Carousel from "../ui/Carousel";
import FeatureItem from "../ui/FeatureItem";
import SectionTitle from "../ui/SectionTitle";
import { PublicComponentProps } from "./PublicComponentProps";
import CollapsableSection, {
  CollapsableSectionSettings,
} from "../ui/CollapsableSection";

export interface Settings extends CollapsableSectionSettings {
  maxNumberOfRows: number;
  iconColor: string;
}

interface Props extends PublicComponentProps<Settings> {
  item?: Item;
}

const Features: FunctionComponent<Props> = ({ item, settings }: Props) =>
  item?.listingFeatures?.length ? (
    <div>
      <CollapsableSection
        isCollapsable={settings.collapseSection}
        id="features-accordion"
        title="Features"
      >
        {!settings.collapseSection && <SectionTitle>Features</SectionTitle>}
        <Carousel
          rowsPerView={settings?.maxNumberOfRows}
          slidesPerViewOnDesktop={3}
          fullWidthSwiper
        >
          {item.listingFeatures?.map(({ body, name }, idx) => (
            <FeatureItem
              key={idx}
              iconColor={settings.iconColor}
              title={name}
              thinTitle
            >
              {body && <Typography>{body}</Typography>}
            </FeatureItem>
          ))}
        </Carousel>
      </CollapsableSection>
    </div>
  ) : (
    <></>
  );

export default Features;
