import { HTTPClient, HTTPResponse } from "../../HTTPClient";

export class HTTPClientEmbedly extends HTTPClient {
  protected host = "https://api.embedly.com/1/oembed";

  protected async request<T>(
    url: string,
    config: RequestInit,
  ): Promise<HTTPResponse<T>> {
    return fetch(this.getRequestUrl(url, { noSlash: true }), config);
  }
}

export const APIEmbedly = new HTTPClientEmbedly();
