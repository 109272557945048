import {
  FormHelperText,
  Icon,
  Rating,
  Typography,
  styled,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useUser } from "../../dataAccess/api/user";
import { ReviewPayload } from "../../dataAccess/api/review";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { InlineMessage } from "./InlineMessage";
import { ButtonProgress } from "./ButtonProgress";
import TextField from "./TextField";
import { TextButton } from "./TextButton";
import { EditorCtx } from "../../wysiwyg/EditorCtx";

interface Props {
  loading?: boolean;
  onSubmit: (data: ReviewPayload) => Promise<boolean>;
  onCancel: () => void;
  className?: string;
  isInvalidCaptcha?: boolean;
  errorMessage?: string;
}

const Content = styled("div")(() => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  width: "100%",
  gap: "1rem",
}));

const CaptchaContainer = styled("div")(() => ({
  marginTop: ".5rem",
  alignSelf: "center",
}));

const Main = styled(Content)(() => ({
  flexDirection: "column",
}));

const Subtitle = styled(Typography)(() => ({
  maxWidth: "25rem",
}));

const FormButton = styled(ThemeButton)(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const StyledRating = styled(Rating)(() => ({
  alignSelf: "center",
}));

const StyledStarIcon = styled(Icon)(() => ({
  opacity: 0.5,
}));

const schema = yup.object().shape({
  quote: yup.string().required("The review quote is mandatory"),
  ratingStars: yup.number().required("The rating is mandatory"),
});

const captchaClass = "g-recaptcha";

const ReviewForm: FunctionComponent<Props> = ({
  loading,
  className,
  onSubmit,
  isInvalidCaptcha,
  errorMessage,
  onCancel,
}: Props) => {
  const [submited, setSubmited] = useState(false);
  const { user } = useUser();
  const { isEditor, messages, setCurrentMessage } = useContext(EditorCtx);
  const { control, formState, handleSubmit } = useForm<ReviewPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      quote: "",
    },
  });

  useEffect(() => {
    if (window.grecaptcha) {
      const captchaElement: HTMLElement | null = document.querySelector(
        `.${captchaClass}`,
      );
      if (captchaElement) {
        try {
          window.grecaptcha.render(captchaElement);
        } catch (e) {
          // Try catch due to double render in dev env, should not be needed in production.
          console.error(e);
        }
      }
    }
  }, []);

  const title = <Typography variant="h4">Share your review</Typography>;
  if (submited) {
    return (
      <>
        {title}
        <InlineMessage>
          <Typography variant="h3">Thanks for sharing your review!</Typography>
          <Subtitle>
            Your review is currently under review, once it gets approved, you
            will see it posted on the review&apos;s list.
          </Subtitle>
          <FormButton
            color="primary"
            variant="contained"
            onClick={() => {
              setSubmited(false);
              onCancel();
            }}
          >
            Accept
          </FormButton>
        </InlineMessage>
      </>
    );
  }
  return (
    <form
      onSubmit={handleSubmit(async (payload) => {
        if (isEditor) {
          setCurrentMessage(messages.previewAlert);
          return;
        }
        if (await onSubmit(payload)) {
          if (!user) {
            window.grecaptcha.reset();
          }
          setSubmited(true);
        }
      })}
      aria-label="Share your review" // TODO: handle translation
    >
      <Main {...(className && { className })}>
        {title}
        <Controller
          name="ratingStars"
          control={control}
          render={({ field }) => (
            <StyledRating
              {...field}
              size="large"
              emptyIcon={
                <StyledStarIcon fontSize="inherit">star</StyledStarIcon>
              }
            />
          )}
        />
        <Controller
          name="quote"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              minRows={5}
              maxRows={15}
              placeholder="What do you think about this product or service?"
              error={!!formState.errors.quote || !!formState.errors.ratingStars}
              helperText={
                formState.errors.quote?.message ||
                formState.errors.ratingStars?.message ||
                ""
              }
            />
          )}
        />
        <Content>
          <TextButton onClick={onCancel} disabled={loading}>
            Cancel
          </TextButton>
          <FormButton
            color="primary"
            type="submit"
            variant="contained"
            startIcon={loading ? <ButtonProgress /> : null}
            disabled={loading}
          >
            Post my review
          </FormButton>
        </Content>
        {!user && (
          <CaptchaContainer>
            <div
              className={captchaClass}
              data-sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}
            />
          </CaptchaContainer>
        )}

        {isInvalidCaptcha && (
          <FormHelperText error>
            Please complete the captcha validation.
          </FormHelperText>
        )}
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Main>
    </form>
  );
};

export default ReviewForm;
