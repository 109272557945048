import { styled } from "@mui/material";

export const InlineMessage = styled("div")(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  borderRadius: "3rem",
  flexDirection: "column",
  textAlign: "center",
  flexWrap: "wrap",
  gap: "1rem",
  backgroundColor: theme.palette.background.paper,
  padding: "1.8rem",
}));
