import { FunctionComponent } from "react";
import { Icon, lighten, Link, styled, Typography } from "@mui/material";
import { ThemeChip } from "../ThemeComponents/ThemeChip";
import { MoreInformationItemVariant } from "../MoreInformationSection";

interface Props {
  label: string;
  variant: MoreInformationItemVariant;
  url?: string;
}

const ChipFeature = styled(ThemeChip)(() => ({
  marginBottom: "0.5rem",
  marginRight: "0.5rem",
  "&.MuiChip-outlined": {
    pointerEvents: "none",
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: `${theme.typography.body1.fontSize} !important`,
}));

const LinkWrapper = styled(Link)<{ featureStyle: Props["variant"] }>(
  ({ featureStyle, theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    color:
      featureStyle === "text"
        ? lighten(theme.typography.allVariants.color, 0.3)
        : theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  }),
);

const Text = styled(Typography)(({ theme }) => ({
  color: lighten(theme.typography.allVariants.color, 0.3),
}));

const MoreInformationFeature: FunctionComponent<Props> = ({
  label,
  variant,
  url,
}: Props) => {
  const localChip = (
    <ChipFeature
      size="small"
      label={label}
      variant="outlined"
      color="primary"
    />
  );

  if (url && label) {
    return (
      <LinkWrapper featureStyle={variant} href={url} target="_blank">
        {variant === "chip" ? localChip : label}
        <StyledIcon>open_in_new</StyledIcon>
      </LinkWrapper>
    );
  }
  return variant === "chip" ? localChip : <Text>{label}</Text>;
};

export default MoreInformationFeature;
