import { styled } from "@mui/material";

interface Props {
  embedHtml: string;
}

const Container = styled("div")(() => ({
  background: "#000",
  height: "100%",
  "& .embedly-embed": {
    width: "100%",
    height: "100%",
  },
}));

const Video = ({ embedHtml }: Props) =>
  embedHtml ? (
    <Container dangerouslySetInnerHTML={{ __html: embedHtml }} />
  ) : null;

export default Video;
