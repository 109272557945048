import { FunctionComponent } from "react";
import { Rating, Typography, styled } from "@mui/material";
import Image from "next/legacy/image";
import Panel, { PanelVariant } from "../Panel";
import { Review } from "../../../dataAccess/api/review";

interface Props {
  review?: Review;
  variant: PanelVariant;
  showRating?: boolean;
  showReviewerName?: boolean;
  imageSize: 80 | 160;
}

const CardContent = styled("div")(() => ({
  marginLeft: "1rem",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.default,
}));

const ReviewerName = styled(Typography)(({ theme }) => ({
  marginTop: "0.5rem",
  marginBottom: "0.25rem",
  color: theme.palette.primary.main,
}));

const ImageContainer = styled("div")(({ theme }) => ({
  width: "100%",
  "> div": {
    position: "unset !important",
  },
  "& > img": {
    width: "100% !important",
    height: "auto !important",
  },
  [theme.breakpoints.up("md")]: {
    width: "auto",
    flexShrink: 0,
  },
}));

const ReviewCard: FunctionComponent<Props> = ({
  review,
  variant,
  showReviewerName,
  showRating,
  imageSize,
}) => (
  <Panel variant={variant}>
    {review?.reviewerPhoto && (
      <ImageContainer>
        <Image
          src={review.reviewerPhoto}
          width={imageSize}
          height={imageSize}
        />
      </ImageContainer>
    )}
    <CardContent>
      <Description>{review?.quote}</Description>
      {showReviewerName && <ReviewerName>{review?.reviewerName}</ReviewerName>}
      {showRating && <Rating value={review?.ratingStars} readOnly />}
    </CardContent>
  </Panel>
);

export default ReviewCard;
