import { APIV3 } from "../HTTPClientV3";
import { defaultFetcher } from "../HTTPClient";
import { JSONAPIDocument, serializers } from "../Serializer";

export enum RedemptionsStep {
  Requested = "requested",
  Completed = "completed",
}

export enum RedemptionsState {
  Default = "default",
  Support = "support",
}

export interface Redemption {
  discountId: number;
  communityId: number;
  userId: number;
  id?: number;
  state?: RedemptionsState;
  step?: RedemptionsStep;
  createdAt: string;
  updatedAt: string;
  redeemed: boolean;
  redeemedAt?: string;
  savingAmount: number;
  landingPageUrl?: string;
}

export interface GuestFlowRedemption {
  email: string;
  signUpOrganizationName: string;
  fullName: string;
  termsAndConditions?: boolean;
}
const base = "/discounts";

export function postRedemption(
  dealId: string,
  redemption?: GuestFlowRedemption,
) {
  delete redemption?.termsAndConditions;
  const payload = serializers.redemption.serialize(redemption);
  const headers = new Headers();
  const url = `${base}/${dealId}/redemption`;
  if (!payload.data) {
    payload.data = { type: serializers.redemption.getType(), id: "" };
  }
  const request = () =>
    APIV3.post<JSONAPIDocument<Redemption>>(url, {
      body: JSON.stringify(payload),
      headers,
    });
  return {
    json: () => defaultFetcher(request, serializers.redemption),
    fallbackKey: url,
  };
}

export function postContactSupport(dealId: string) {
  const url = `${base}/${dealId}/contact_support`;
  const request = () => APIV3.post<JSONAPIDocument<Redemption | null>>(url);
  return {
    json: () => defaultFetcher(request, serializers.redemption),
    fallbackKey: url,
  };
}

export function postResendEmail(dealId: string) {
  const url = `${base}/${dealId}/resend_instructions_email`;
  const request = () => APIV3.post<JSONAPIDocument<Redemption>>(url);
  return {
    json: () => defaultFetcher(request, serializers.redemption),
    fallbackKey: url,
  };
}
