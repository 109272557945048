import { Icon, styled, Typography } from "@mui/material";
import type { CSSProperties, ReactNode } from "react";
import type { Alignment } from "../../../types/theme";

interface Props {
  children: ReactNode;
  title?: string;
  thinTitle?: boolean;
  iconColor?: string;
  hideIcon?: boolean;
  justification?: Alignment;
}

const Main = styled("div")<Pick<Props, "justification">>(({
  justification,
}) => {
  const styles: CSSProperties = {
    flexDirection: "row",
  };
  switch (justification) {
    case "center":
      styles.flexDirection = "column";
      styles.alignItems = "center";
      break;
    case "right":
      styles.flexDirection = "row-reverse";
      break;
    default:
      break;
  }
  return {
    display: "flex",
    gap: ".5rem",
    ...styles,
  };
});

const Title = styled(Typography)<{ thinTitle: boolean }>(
  ({ theme, thinTitle }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    ...(thinTitle
      ? {
          fontWeight: theme.typography.body1.fontWeight,
        }
      : {}),
  }),
);

const Content = styled("div")(() => ({
  flex: 1,
}));

const StyledIcon = styled(Icon)<Pick<Props, "iconColor">>(
  ({ theme, iconColor }) => ({
    color: iconColor || theme.palette.success.main,
  }),
);

const FeatureItem = ({
  iconColor,
  children,
  title,
  hideIcon,
  justification,
  thinTitle,
}: Props) => (
  <Main justification={justification}>
    {!hideIcon && <StyledIcon iconColor={iconColor}>check_circle</StyledIcon>}
    <Content>
      {title && (
        <Title variant="h6" thinTitle={!!thinTitle}>
          {title}
        </Title>
      )}
      {children}
    </Content>
  </Main>
);

export default FeatureItem;
