import { defaultFetcher, fetcherWithHeaders } from "../HTTPClient";
import { JSONAPIDocument, serializers } from "../Serializer";
import { APIV3 } from "../HTTPClientV3";
import { User } from "./user";
import { sessionStorage } from "../storages/session";
import { setAuthorizationHeaders } from "../../app/Authorization";

export interface GuestUser {
  email: string;
  firstName: string;
  lastName: string;
  guestUser?: boolean;
  signUpOrganizationName?: string;
  guestEmailVerifications?: string[];
}

interface CreateGuestPayload extends GuestUser {
  discountId: number;
}

interface GuestEmailVerificationPayload {
  verificationId: string;
}

const base = "guest_user";

export function isGuestUser(user?: User) {
  return !!user?.guestUser;
}

export function createGuest(payload: CreateGuestPayload) {
  const request = () =>
    APIV3.post<JSONAPIDocument<GuestUser>>(base, {
      body: JSON.stringify(serializers.token.serialize(payload)),
    });
  return {
    json: async () => {
      const response = await fetcherWithHeaders(request, serializers.token);
      setAuthorizationHeaders(response.headers);
      sessionStorage.loadUser({ jwt: "", cid: "" });
      return response;
    },
    fallbackKey: base,
  };
}

export function resendGuestUserVerification(
  payload: GuestEmailVerificationPayload,
) {
  const url = `guest_email_verifications/${payload.verificationId}/resend`;
  const request = () => APIV3.post<JSONAPIDocument<GuestUser>>(url);
  return {
    json: async () => defaultFetcher(request, serializers.token),
    fallbackKey: url,
  };
}
